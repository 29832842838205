import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import api from '../../api';
import getImageUrl from '../../assets/helpers/getImageUrl';
// import FileInputField from '../../components/base/FileInputField';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import videoPlaceholder from '../../assets/img/video-placeholder.png';
import FileInputField from '../../components/base/FileInputField';
import InputField from '../../components/base/InputField';
import PrimaryButton from '../../components/base/PrimaryButton';
import PageLoader from '../../components/PageLoader';
import SelectField from '../../components/base/SelectField';
import SelectBox from '../../components/base/SelectBox';
import TextareaField from '../../components/base/TextareaField';
import {useSelector} from "react-redux";
import {getUser} from "../../redux/slices/applicationSlice";


function convertToDotNotation(pathArray) {
    if (!Array.isArray(pathArray)) return pathArray
    return pathArray?.reduce((acc, cur) => {
        if (typeof cur === 'number') {
            return `${acc}[${cur}]`;
        } else if (typeof cur === 'string' && acc) {
            return `${acc}[${cur}]`;
        } else {
            return cur;
        }
    }, '');
}

const VideoInterview = () => {
    const {id, step} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [existedInterview, setExistedInterview] = useState(null);
    const [defaultInterview, setDefaultInterview] = useState(null);
    const user = useSelector(getUser)
    const [templates, setTemplates] = useState(null);
    const [preset, setPreset] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        questions: [{title: "", description: "", video: null, qaid: null}],
        outro: {title: "", description: ""},
        introVideo: null,
        outroVideo: null,
        type: step || "1st-interview"
    });


    const [videosToRemove, setVideosToRemove] = useState([])

    const handleChange = (path, value) => {
        setFormData(prev => {
            const newFormData = {...prev};
            const keys = Array.isArray(path) ? path : [path];
            let current = newFormData;

            keys.slice(0, -1).forEach(key => {
                if (!current[key]) {
                    current[key] = isNaN(keys[keys.length - 1]) ? {} : [];
                }
                current = current[key];
            });
            current[keys[keys.length - 1]] = value;
            return newFormData;
        });
    };

    const handleFileChange = (path, file) => {
        handleChange(path, file);
        if (file) {
            setVideosToRemove(prevVideosToRemove =>
                prevVideosToRemove.filter(item => {
                    return item !== (Array.isArray(path) ? convertToDotNotation(path) : path)
                }));
        }
    };
    const handleRemoveVideo = (path) => {
        handleChange(path, null);

        const copy = [...videosToRemove];
        const index = copy.findIndex(item => item === path);
        if (index < 0) {
            if (Array.isArray(path)) {
                copy.push(convertToDotNotation(path))
            } else {
                copy.push(path);
            }
        }
        setVideosToRemove(copy);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const videos = []
        const formdata = new FormData();
        formdata.append("name", formData.name);
        if (formData.questions?.length) {
            formData.questions.forEach((question, index) => {
                formdata.append(`questions[${index}][title]`, question.title);
                formdata.append(`questions[${index}][description]`, question.description);
                formdata.append(`questions[${index}][qaid]`, question.qaid);
                if (existedInterview?.questions?.[index]?.media?.id && !videosToRemove.includes(`questions[${index}][video]`)) {
                    formdata.append(`questions[${index}][media][id]`, existedInterview?.questions?.[index]?.media?.id || null);
                }
                if (!videosToRemove.includes(`questions[${index}][video]`) && question.video) {
                    if (existedInterview?.questions?.[index]?.id) {
                        formdata.append(`questions[${index}][id]`, existedInterview?.questions?.[index]?.id || null);
                    }
                    formdata.append(`questions[${index}][video]`, videos.length);
                    videos.push(question?.video)
                }
            });
            videos.forEach(video => {
                formdata.append(`questionVideos`, video);
            })
        } else {
            // formdata.append(`questions`, JSON.stringify([]));
        }

        formdata.append("outro[title]", formData.outro.title);
        formdata.append("outro[description]", formData.outro.description);

        if (!videosToRemove.includes("outroVideo") && existedInterview?.outro?.media && !formData.outroVideo) {
            formdata.append(`outro[media][id]`, existedInterview?.outro?.media?.id);
        }

        if (!videosToRemove.includes("introVideo") && formData.introVideo) {
            formdata.append("introVideo", formData.introVideo);
        }
        if (videosToRemove.includes("introVideo")) {
            formdata.append("intro", null);
        }
        if (formData.outroVideo) {
            formdata.append("outroVideo", formData.outroVideo);
        }

        formdata.append("type", formData.type);
        if (!id) return;

        setIsLoading(true);

        try {
            const res = existedInterview
                ? await api.jobVideoInterview.updateVideoInterview({id: existedInterview.id, jobId: id, body: formdata})
                : await api.jobVideoInterview.createVideoInterview({jobId: id, body: formdata});

            if (res.success) {
                toast.success("Saved successfully");
                getVideoInterview(formData.type);
                resetForm();
            } else {
                if (res?.errors?.length) {
                    res?.errors?.forEach(err => {
                        toast.error(err?.field + " " + err?.msg);
                    })

                } else {
                    toast.error("something went wrong");
                }
            }
        } catch (error) {

            toast.error("An error occurred while saving the interview");
        } finally {
            setIsLoading(false);
        }
    };

    const addQuestion = () => {
        setFormData(prev => ({
            ...prev,
            questions: [...prev.questions, {title: "", description: "", qaid: null, video: null}]
        }));
    };

    const removeQuestion = (index) => {
        setFormData(prev => ({
            ...prev,
            questions: prev.questions.filter((_, i) => i !== index)
        }));
        setExistedInterview(prev => ({
            ...prev,
            questions: prev.questions.filter((_, i) => i !== index)
        }));
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const updatedQuestions = Array.from(formData.questions);
        const [movedQuestion] = updatedQuestions.splice(result.source.index, 1);
        updatedQuestions.splice(result.destination.index, 0, movedQuestion);

        setFormData(prev => ({
            ...prev,
            questions: updatedQuestions
        }));

        const updatedExistedQuestions = Array.from(existedInterview?.questions);
        const [movedExistedQuestions] = updatedExistedQuestions.splice(result.source.index, 1);
        updatedExistedQuestions.splice(result.destination.index, 0, movedExistedQuestions);

        setExistedInterview(prev => ({
            ...prev,
            questions: updatedExistedQuestions
        }));
    };

    const getVideoInterview = async (step) => {
        const interviewQuestionsRes = await getInterviewQuestions(step)
        setIsLoading(true);
        try {
            const res = await api.jobVideoInterview.getVideoInterview({jobId: id, type: step});
            if (res.data) {
                setFormData({
                    name: res.data.name,
                    questions: (step === '1st-interview' && interviewQuestionsRes) ?
                        interviewQuestionsRes.map((item, index) => ({
                            title: `Question ${index + 1}`,
                            description: item.question,
                            qaid: item.id,
                            video: null
                        })) :
                        res.data.questions.map((question, index) => ({
                            title: question?.title,
                            description: question?.description,
                            video: null,
                            qaid: res.data?.qualityQuestions?.[index]?.id || null
                        })) || [],
                    outro: {
                        title: res.data?.outro?.title,
                        description: res?.data.outro?.description
                    },
                    introVideo: null,
                    outroVideo: null,
                    type: step
                });
                setExistedInterview(res.data);
                setDefaultInterview(res.data);
            } else {
                if (step === '1st-interview' && interviewQuestionsRes) {
                    setFormData({
                        name: "",
                        questions: interviewQuestionsRes.map((item, index) => ({
                            title: `Question ${index + 1}`,
                            description: item.question,
                            qaid: item.id,
                            video: null
                        })),
                        outro: {title: "", description: ""},
                        introVideo: null,
                        outroVideo: null,
                        type: step || "1st-interview"
                    });
                    setExistedInterview(null);
                } else {
                    resetForm();
                }
            }
        } catch (error) {

            toast.error("An error occurred while fetching the interview");
        } finally {
            setIsLoading(false);
        }
    };

    const [interviewQuestions, setInterviewQuestions] = useState([]);
    const questionOptions = interviewQuestions.map(item => ({
        value: item.id,
        label: item.question
    }))
    const getInterviewQuestions = async (step) => {
        if (!step) return false
        setIsLoading(true);
        try {
            const res = await api.jobVideoInterview.getInterviewQuestions(step === '1st-interview' ? 'Video Interview' : 'In Person Interview');
            if (!res.success) {
                toast.error(res?.message || "An error occurred while fetching the interview questions");
            }

            setInterviewQuestions(res.data)
            return res.data
        } catch (error) {
            return false
            toast.error("An error occurred while fetching the interview questions");
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        setFormData({
            name: "",
            questions: [{title: "", description: "", qaid: null, video: null}],
            outro: {title: "", description: ""},
            introVideo: null,
            outroVideo: null,
            type: step || "1st-interview"
        });
        setExistedInterview(null);
    };

    useEffect(() => {
        if (!id || !formData.type || !step) return;
        getVideoInterview(step);
        getTemplates()
    }, [id, step]);

    const isFirstInteview = step === '1st-interview'

    if (isLoading) {
        return <PageLoader/>;
    }

    const getTemplates = async () => {
        const res = await api.jobVideoInterview.getVideoInterviewTemplates();
        if (res.data) {
            setTemplates(res.data)
        }
    };


    const getPreset = async (one) => {
        const preset = templates.find(item => item.id == one.target.value)
        try {
            const res = await api.jobVideoInterview.getVideoInterviewPreset({
                jobId: preset.jobId,
                jobVideoInterviewId: preset.id
            });
            if (res.data) {
                setPreset(res.data)
                setFormData({
                    name: res.data.name,
                    questions: res.data.questions,
                    outro: res.data.outro,
                    introVideo: null,
                    outroVideo: null,
                    type: step
                });
                setExistedInterview(res.data);
            }
        } catch (e) {
            console.log("e", e)
        }
    };

    const clone = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            await api.jobVideoInterview.cloneVideoInterview({
                jobId: id,
                jobVideoInterviewId: preset?.id
            })
            setPreset(null)
            setExistedInterview(null);
            getVideoInterview(step);
        } finally {
            setIsLoading(false)
        }
    };

    const clear = () => {
        getVideoInterview(step);
        setExistedInterview(null);
        setPreset(null)
    };

    const isPreview = !defaultInterview && preset;

    return (
        <form onSubmit={preset ? clone : handleSubmit}
              className='flex flex-col w-full flex-auto pt-4 pb-20 lg:pb-24 mx-auto max-w-[1440px] px-5'>
            <h2 className='text-4xl font-bold mb-12'>{step}</h2>
            <div className='mb-6'>
                <InputField
                    disabled={isPreview}
                    label='Interview Name *'
                    value={formData.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                />
            </div>
            {!defaultInterview && templates && <div className="flex flex-col mb-4 gap-4 w-full">
                <label className='block'>Template</label>
                <div className="flex items-center gap-5 w-full">
                    <SelectField
                        inputBg="bg-white rounded-md border border-solid border-zinc-300 w-min"
                        name="industrieId"
                        id="industrieId"
                        parentClass=''
                        onChange={getPreset}
                        options={[{label: "Choose Template", value: ""}, ...templates?.map(item => ({
                            label: item?.name,
                            value: item?.id
                        }))]}
                    />
                    {preset && <PrimaryButton className="!h-[54px] px-3 bg-red-500" type="button" onClick={clear}>Clear
                        template</PrimaryButton>}
                </div>
            </div>}
            <div className='mb-6'>
                <div className='flex justify-between gap-6 mb-2'>
                    <label className='block mb-2'>Intro Video</label>
                    {!isPreview &&
                    !videosToRemove.includes("introVideo") && !(!formData.introVideo && !existedInterview?.intro?.url) ?
                        <PrimaryButton onClick={() => handleRemoveVideo("introVideo")} type="button"
                                       className={"bg-red-500 !h-8 px-4"}>Remove</PrimaryButton>
                        : null
                    }
                </div>
                {!videosToRemove.includes("introVideo") && !(!formData.introVideo && !existedInterview?.intro?.url) ?
                    <video className='w-full aspect-video mb-4'
                           src={(getImageUrl(formData.introVideo) || existedInterview?.intro?.url)}
                           controls
                           poster={!formData.introVideo ? existedInterview?.intro?.thumbnail : ""}
                    />
                    :
                    <img className='w-full aspect-video mb-4' src={videoPlaceholder} alt="intro"/>
                }
                {!isPreview && <FileInputField
                    hideActions={true}
                    record={true}
                    boxClassName='!max-w-full'
                    acceptProp={{'video/*': ['.mp4', '.avi']}}
                    handleChange={(file) => handleFileChange("introVideo", file)}
                    isVideo
                    handleRemove={() => handleRemoveVideo("introVideo")}
                    fileProp={formData.introVideo}
                />}

            </div>
            <h4 className='text-2xl font-bold mb-6'>Questions</h4>
            {isFirstInteview ? <div className='grid lg:grid-cols-1 gap-8 mb-6'>
                {formData.questions.map((question, index) => (
                    <div className='flex items-center w-full' key={index}>
                        <div
                            className='flex flex-wrap lg:flex-nowrap items-center bg-white gap-9 border py-4 rounded w-full mb-3'
                        >
                            <div
                                className='max-lg:border-b py-4 lg:border-r border-solid max-lg:w-full justify-center flex flex-col items-center px-4'>
                                <h2 className='text-4xl font-bold'>{index + 1}</h2>
                            </div>
                            <div className='w-full flex justify-between'>
                                <div className='pl-4 w-1/2'>
                                    <div className='flex justify-between gap-6 mb-2'>
                                        <label className='block mb-2'>Question Video</label>
                                        {!isPreview &&
                                        !videosToRemove.includes(`questions[${index}][video]`) && !(!question.video && !existedInterview?.questions?.[index]?.media?.url) ?
                                            <PrimaryButton
                                                onClick={() => handleRemoveVideo(["questions", index, "video"])}
                                                type="button"
                                                className={"bg-red-500 !h-8 px-4"}>Remove</PrimaryButton>
                                            : null
                                        }
                                    </div>
                                    {!videosToRemove.includes(`questions[${index}][video]`) && !(!question.video && !existedInterview?.questions?.[index]?.media?.url) ?
                                        <video
                                            className='aspect-video w-[500px] mb-4'
                                            src={!videosToRemove.includes(`questions[${index}][video]`) ? getImageUrl(question.video) || existedInterview?.questions?.[index]?.media?.url : null}
                                            controls
                                            poster={!videosToRemove.includes(`questions[${index}][video]`) ? !question.video && existedInterview?.questions?.[index]?.media?.thumbnail : ""}
                                        />
                                        :
                                        <img className='w-full aspect-video mb-4' src={videoPlaceholder} alt="intro"/>
                                    }

                                    {!isPreview && <FileInputField
                                        hideActions={true}
                                        record={user.role !== "subscriber"}
                                        boxClassName='!max-w-full'
                                        acceptProp={{'video/*': ['.mp4', '.avi']}}
                                        handleChange={(file) => handleFileChange(["questions", index, "video"], file)}
                                        isVideo
                                        handleRemove={() => handleRemoveVideo(["questions", index, "video"])}
                                        fileProp={question.video}
                                    />}
                                </div>
                                <div className='w-1/2 flex flex-col pl-4 pr-4'>
                                    <InputField
                                        parentClassName="flex-none"
                                        label='Title'
                                        disabled
                                        value={question.title}
                                        onChange={(e) => handleChange(["questions", index, "title"], e.target.value)}
                                    />
                                    <TextareaField
                                        parentClassName="flex-none"
                                        label='Description'
                                        disabled
                                        value={question.description}
                                        onChange={(e) => handleChange(["questions", index, "description"], e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div> : <>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="questions">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}
                                 className='grid lg:grid-cols-1 gap-8 mb-6'>
                                {formData.questions.map((question, index) => (
                                    <div className='flex items-center w-full' key={index}>
                                        <Draggable draggableId={`question-${index}`} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}

                                                    className='flex flex-wrap lg:flex-nowrap items-center bg-white gap-9 border py-4 rounded w-full mb-3'
                                                >
                                                    <div
                                                        className='max-lg:border-b py-4 lg:border-r border-solid max-lg:w-full justify-center flex flex-col items-center px-4'>
                                                        <h2 className='text-4xl font-bold'>{index + 1}</h2>
                                                    </div>
                                                    <div className='w-full flex justify-between'>
                                                        <div className='pl-4 w-1/2'>
                                                            <div className='flex justify-between gap-6 mb-2'>
                                                                <label className='block mb-2'>Question Video</label>
                                                                {
                                                                    !videosToRemove.includes(`questions[${index}][video]`) && !(!question.video && !existedInterview?.questions?.[index]?.media?.url) ?
                                                                        <PrimaryButton
                                                                            onClick={() => handleRemoveVideo(["questions", index, "video"])}
                                                                            type="button"
                                                                            className={"bg-red-500 !h-8 px-4"}>Remove</PrimaryButton>
                                                                        : null
                                                                }
                                                            </div>
                                                            {!videosToRemove.includes(`questions[${index}][video]`) && !(!question.video && !existedInterview?.questions?.[index]?.media?.url) ?
                                                                <video
                                                                    className='aspect-video w-[500px] mb-4'
                                                                    src={!videosToRemove.includes(`questions[${index}][video]`) ? getImageUrl(question.video) || existedInterview?.questions?.[index]?.media?.url : null}
                                                                    controls
                                                                    poster={!videosToRemove.includes(`questions[${index}][video]`) ? !question.video && existedInterview?.questions?.[index]?.media?.thumbnail : ""}
                                                                />
                                                                :
                                                                <img className='w-full aspect-video mb-4'
                                                                     src={videoPlaceholder} alt="intro"/>
                                                            }

                                                            <FileInputField
                                                                hideActions={true}
                                                                record={true}
                                                                boxClassName='!max-w-full'
                                                                acceptProp={{'video/*': ['.mp4', '.avi']}}
                                                                handleChange={(file) => handleFileChange(["questions", index, "video"], file)}
                                                                isVideo
                                                                handleRemove={() => handleRemoveVideo(["questions", index, "video"])}
                                                                fileProp={question.video}
                                                            />
                                                        </div>
                                                        <div className='w-1/2 flex flex-col pl-4 pr-4'>
                                                            <InputField
                                                                parentClassName="flex-none"
                                                                label='Title'
                                                                value={question.title}
                                                                onChange={(e) => handleChange(["questions", index, "title"], e.target.value)}
                                                            />
                                                            <InputField
                                                                parentClassName="flex-none"
                                                                label='Description'
                                                                disabled
                                                                value={question.description}
                                                                onChange={(e) => handleChange(["questions", index, "description"], e.target.value)}
                                                            />
                                                            <SelectBox
                                                                parentClass='flex flex-col gap-[16px] max-md:gap-[12px] flex-1 mb-[18px] flex-none'
                                                                label='QA Question'
                                                                options={questionOptions.filter(item => !formData.questions.find(questionItem => (questionItem.qaid === item.value && questionItem.qaid !== question.qaid)))}
                                                                value={question.qaid}
                                                                onSelect={(value) => {
                                                                    handleChange(["questions", index, "qaid"], value)
                                                                    handleChange(["questions", index, "description"], questionOptions.find(item => item.value === value).label)
                                                                }}
                                                            />
                                                            <PrimaryButton
                                                                type='button'
                                                                className='px-6 bg-red-500 text-red-500 mt-auto ml-auto'
                                                                onClick={() => removeQuestion(index)}
                                                            >
                                                                Remove Question
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                    <div
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            display: formData.questions.length > 1 ? "flex" : "none",
                                                        }}
                                                        className='transition hover:bg-[#F0F3F8] max-lg:w-full flex items-center justify-center flex-col max-lg:border-t lg:border-l border-solid px-4 py-4 lg:rounded-e -my-4'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                                                             width={30} height={50}>
                                                            <path
                                                                d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    </div>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className='flex justify-end'>
                    <PrimaryButton type='button' onClick={addQuestion}
                                   className='text-white px-4 py-2 rounded max-w-80 w-full'>
                        Add Question
                    </PrimaryButton>
                </div>
            </>}
            <div className='mb-6 pt-6'>
                <h4 className='text-2xl font-bold mb-4'>Outro</h4>
                <InputField
                    label='Outro Title'
                    value={formData.outro.title}
                    disabled={isPreview}
                    onChange={(e) => handleChange(["outro", "title"], e.target.value)}
                />
                <InputField
                    disabled={isPreview}
                    label='Outro Description'
                    value={formData.outro.description}
                    onChange={(e) => handleChange(["outro", "description"], e.target.value)}
                />

                <div className='flex justify-between gap-6 mb-2'>
                    <label className='block mb-2'>Outro Video</label>
                    {!isPreview &&
                    !videosToRemove.includes("outroVideo") && !(!formData.outroVideo && !existedInterview?.outro?.media?.url) ?
                        <PrimaryButton onClick={() => handleRemoveVideo("outroVideo")} type="button"
                                       className={"bg-red-500 !h-8 px-4"}>Remove</PrimaryButton>
                        : null
                    }
                </div>
                {
                    !videosToRemove.includes("outroVideo") && !(!formData.outroVideo && !existedInterview?.outro?.media?.url) ?
                        <video
                            className='w-full aspect-video mb-4'
                            src={getImageUrl(formData.outroVideo) || existedInterview?.outro?.media?.url}
                            controls
                            poster={!formData.outroVideo ? existedInterview?.outro?.media?.thumbnail : ""}

                        />
                        :
                        <img className='w-full aspect-video mb-4' src={videoPlaceholder} alt="intro"/>

                }
                {!isPreview && <FileInputField
                    hideActions={true}
                    record={true}
                    boxClassName='!max-w-full'
                    acceptProp={{'video/*': ['.mp4', '.avi']}}
                    handleChange={(file) => handleFileChange("outroVideo", file)}
                    isVideo
                    fileProp={formData.outroVideo}
                    handleRemove={() => handleRemoveVideo("outroVideo")}
                />}
            </div>
            <div className="flex justify-between gap-6">
                {/* <PrimaryButton onClick={resetForm} className="bg-red-500 max-w-80 w-full" type='button'>Cancel</PrimaryButton> */}
                <PrimaryButton className="max-w-80 w-full"
                               type='submit'>{preset ? "Save Clone" : "Save"}</PrimaryButton>
            </div>
        </form>
    );
};

export default VideoInterview;
