export const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function average(arr, prop, total) {
    if (!arr?.length) return 'N/A';

    const sum = arr?.reduce((acc, obj) => acc + Number(obj[prop]), 0);

    return Math.round(sum / arr.length);
}

export function averageFixed(arr, prop, fixed = 2) {
    if (!arr?.length) return 'N/A';

    const sum = arr.reduce((acc, obj) => acc + Number(obj[prop]), 0);

    return (sum / arr.length).toFixed(fixed)
}
