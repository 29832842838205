import React, { useEffect, useState } from "react";


import BoxEditSvg from '../../../assets/img/bx-edit.svg'

import { useParams } from "react-router-dom";
import BreadCrumbWhite from "../../../components/BreadCrumbWhite";
import { TabsSBM01 } from "../base/TabsSBM01";
import SelectBox from "../../../components/base/SelectBox";
import { Footer } from "../../../components/parts/Footer";
import api from "../../../api";
import { array, number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import classNames from "classnames";
import PageLoader from "../../../components/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationByIdFetch, getJobByIdFetch } from "../../../redux/thunks/jobsThunks";
import { baseCrumbs } from "../config";



const SBM01_10_14_4 = () => {
    const { jobId, applicationId } = useParams();
    const dispatch = useDispatch();
    const { job, isLoading: isJobLoading, application } = useSelector(state => state.jobs)

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (!applicationId) return;
        setIsLoading(true)
        api.applicationReferences.get(applicationId).then(response => {
            if (!response.success) {
                return;
            }
            setValue('items', response.data)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [applicationId])

    useEffect(() => {
        if (!jobId || !applicationId) return;
        dispatch(getJobByIdFetch(jobId))
        dispatch(getApplicationByIdFetch({
            applicationId,
            jobId
        }))
    }, [jobId, applicationId, dispatch])
    const schema = object({
        items: array().of(object({
            referenceName: string().required('This field is required'),
            email: string().email().required('This field is required'),
            phoneNumber: string().required('This field is required'),
            companyName: string().required('This field is required'),
            references: array().required().of(object({
                question: string().required('This field is required'),
                score: number().required(),
                comment: string().required()
            })),
        })).required()
    });

    const { register, control, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });
    const itemsErrors = errors?.items;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "items",
        keyName: null
    });
    const onSubmit = async (data) => {
        const created = [];
        const updated = [];
        data.items.forEach((item) => {
            if (item.id) {
                updated.push(item);
            } else {
                created.push(item);
            }
        })
        if (created.length) {
            const response = await api.applicationReferences.create(applicationId, {
                "items": created
            });
            if (response.success) {
                toast.success('Reference(s) created successfully!')
            } else {
                toast.error('Some fields invalid')
            }
        }
        if (updated.length) {
            const response = await api.applicationReferences.update(applicationId, {
                "items": updated
            });
            if (response.success) {
                toast.success('Reference(s) updated successfully!')
            }
        }
    };
    const handleRemove = async (item, index) => {
        if (item.id) {
            const response = await api.applicationReferences.delete(applicationId, item.id);
        }
        remove(index);
    }
    return (
        <>
            {
                !isJobLoading ?
                    <>
                        < BreadCrumbWhite crumbs={
                            [
                                ...baseCrumbs({ jobId, applicationId, fullName: application?.fullName }),
                                "Reference"
                            ]} />

                        <div className='flex flex-col flex-auto'>
                            <div className='flex flex-col flex-auto gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
                                <div className="flex flex-col gap-11 w-full">
                                    <header className="flex gap-5 justify-between w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                                        <div>
                                            <h2 className="text-4xl font-bold leading-10 text-zinc-950">{application?.fullName}</h2>
                                        </div>
                                    </header>
                                    <TabsSBM01 activeLink={"reference"} />
                                </div>
                                {
                                    isLoading ? <PageLoader /> :
                                        fields.length ?
                                            fields.map((item, index) => {
                                                return <div key={index}>
                                                    <div className="w-full overflow-auto">
                                                        <table className="table-auto table-10_14_4 w-full firts-table overflow-auto">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-start min-w-[380px] px-4 py-3">Reference Name</th>
                                                                    <th className="text-start min-w-[250px] px-4 py-3">Email</th>
                                                                    <th className="text-start min-w-[264px] px-4 py-3">Phone Number</th>
                                                                    <th className="text-start min-w-[250px] px-4 py-3">Company Name</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className={classNames("border-b-unset px-4 py-3", {
                                                                        'bg-red-500': itemsErrors?.[index]?.referenceName
                                                                    })}>
                                                                        <input className="w-full bg-inherit" {...register(`items.${index}.referenceName`)} />
                                                                    </td>
                                                                    <td className={classNames("border-b-unset px-4 py-3", {
                                                                        'bg-red-500': itemsErrors?.[index]?.email
                                                                    })}>
                                                                        <input className="w-full bg-inherit" {...register(`items.${index}.email`)} />
                                                                    </td>
                                                                    <td className={classNames("border-b-unset px-4 py-3", {
                                                                        'bg-red-500': itemsErrors?.[index]?.phoneNumber
                                                                    })}>
                                                                        <input className="w-full bg-inherit" {...register(`items.${index}.phoneNumber`)} />
                                                                    </td>
                                                                    <td className={classNames("border-b-unset px-4 py-3", {
                                                                        'bg-red-500': itemsErrors?.[index]?.companyName
                                                                    })}>
                                                                        <input className="w-full bg-inherit" {...register(`items.${index}.companyName`)} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                    <div className="w-full overflow-auto">
                                                        <table className="table-auto table-10_14_4 w-full second-table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-start min-w-[470px] "> </th>
                                                                    <th className="text-start radio-col">
                                                                        <span className="label-fixed">Poor Fit</span>
                                                                    </th>
                                                                    <th className="text-start radio-col min-w-20"> </th>
                                                                    <th className="text-start radio-col min-w-20"> </th>
                                                                    <th className="text-start radio-col min-w-20"> </th>
                                                                    <th className="text-start radio-col min-w-20"> </th>
                                                                    <th className="text-start radio-col min-w-20"> </th>
                                                                    <th className="text-start radio-col min-w-20">
                                                                        <span className="label-fixed">Best Fit</span>
                                                                    </th>
                                                                    <th className="text-start radio-col">Score</th>
                                                                    <th className="text-start comment-col min-w-[290px]">Comments</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {item.references.map((el, idx) => <tr>
                                                                    <td key={idx}>
                                                                        <span className="flex h-full items-start gap-[10px]">
                                                                            <textarea className="w-full h-full resize-none" {...register(`items.${index}.references.${idx}.question`)} />
                                                                            <img src={BoxEditSvg} alt="" />
                                                                        </span>
                                                                    </td>
                                                                    {new Array(7).fill('').map((_, i) =>
                                                                        <td key={i}>
                                                                            <div className="flex h-full items-start justify-center">
                                                                                <label htmlFor={'' + i} className="cursor-pointer radio-green-container w-[40px] font-medium">
                                                                                    <Controller
                                                                                        name={`items.${index}.references.${idx}.score`}
                                                                                        control={control}
                                                                                        render={({ field }) => <input
                                                                                            type="radio"
                                                                                            className="radio-green-sub"
                                                                                            name={el.question}
                                                                                            id={'' + i}
                                                                                            value={i + 1}
                                                                                            checked={field.value === i + 1}
                                                                                            onChange={(e) => field.onChange(i + 1)}
                                                                                        />}
                                                                                    />

                                                                                    <span className="font-semibold text-[18px]">{i + 1}</span>
                                                                                </label>
                                                                            </div>
                                                                        </td>)}

                                                                    <td className="text-center align-top">{watch(`items.${index}.references.${idx}.score`)}</td>
                                                                    <td className={classNames("px-4 py-3", {
                                                                        'bg-red-500': itemsErrors?.[index]?.references?.[idx]?.comment
                                                                    })}>
                                                                        <textarea className="px-4 w-full h-full resize-none bg-inherit" {...register(`items.${index}.references.${idx}.comment`)} />
                                                                    </td>
                                                                </tr>)}
                                                                <tr className="second-table-total">
                                                                    <td className="text-end">
                                                                        <span className="font-bold">Total Score</span>
                                                                    </td>
                                                                    {new Array(9).fill('').map((_, i) => <td className="text-center">
                                                                        {i === 7 && watch(`items.${index}.references`)?.reduce((sum, reference) => sum + reference.score, 0)}
                                                                        {i === 8 && <button
                                                                            className="m-auto text-lg font-bold text-white flex items-center h-[52px] justify-center px-9 py-5 bg-[#D70000] rounded-md max-md:px-5 max-md:w-full max-md:mt-[24px]"
                                                                            onClick={() => handleRemove(item, index)}
                                                                        >
                                                                            Remove Reference
                                                                        </button>}
                                                                    </td>)}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            }) : <p className="text-xl font-semibold text-center">There are no references yet</p>
                                }
                                <div className="flex justify-center pb-[60px] gap-3">
                                    <button
                                        onClick={() => {
                                            append({
                                                "referenceName": "",
                                                "email": "",
                                                "phoneNumber": "",
                                                "companyName": "",
                                                "references": [
                                                    {
                                                        "question": "Where did you work with the candidate?",
                                                        "score": 1,
                                                        "comment": ""
                                                    },
                                                    {
                                                        "question": "Where you her/his supervisor or just a colleague?",
                                                        "score": 1,
                                                        "comment": ""
                                                    },
                                                    {
                                                        "question": "What kind of employees she/he was?",
                                                        "score": 1,
                                                        "comment": ""
                                                    },
                                                    {
                                                        "question": "From 1 to 10 (10 is the best), how would you rate his attendance, Integrity and Coachability?",
                                                        "score": 1,
                                                        "comment": ""
                                                    },
                                                    {
                                                        "question": "Where she/he can further improve?",
                                                        "score": 1,
                                                        "comment": ""
                                                    },


                                                ]
                                            })
                                        }}
                                        className="justify-center py-[15px] max-md:mt-[20px] max-md:flex-auto px-[36px] font-bold text-white bg-sky-900 rounded-md max-md:px-5" tabIndex="0">
                                        Add Reference
                                    </button>
                                    <button
                                        onClick={handleSubmit(onSubmit)}
                                        className="justify-center py-[15px] max-md:mt-[20px] max-md:flex-auto px-[36px] font-bold text-white bg-[#37B34A] rounded-md max-md:px-5" tabIndex="0">
                                        Sumbit
                                    </button>
                                </div>


                            </div>

                            <Footer hideCopyright={true} />
                        </div>
                    </>
                    :
                    <PageLoader />
            }
        </>
    )
}

export default SBM01_10_14_4
