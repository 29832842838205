import React, {useEffect, useState} from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import {Footer} from '../components/parts/Footer';
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {getUser} from "../redux/slices/applicationSlice";
import {getJobByIdFetch, getJobsByCompanyFetch, getJobStats} from "../redux/thunks/jobsThunks";
import DatePicker from "react-datepicker";
import {format} from "date-fns";
import PrimaryButton from "../components/base/PrimaryButton";

const stages = [
    'Clarification',
    'Video Interview',
    'Post Interview Survey',
    'In Person Interview',
    'Document Review',
    'Rating of The Job',
    'AI Recommendation',
    'Final Hiring Decision',
    'Offer Meeting',
    'Training Insights',
    'Training Milestones'
]
const percentageMapping = (percentages, stage) => {
    const mapping = {
        "Clarification": percentages?.clarifications || 0,
        "Video Interview": percentages?.["1st-interview"] || 0,
        "Post Interview Survey": percentages?.post_interview_survey || 0,
        "In Person Interview": percentages?.["2nd-interview"] || 0,
        "Document Review": percentages?.document_review || 0,
        "Rating of The Job": percentages?.rating_of_the_job || 0,
        "AI Recommendation": percentages?.ai_recomendation || 0,
        "Final Hiring Decision": percentages?.final_hiring || 0,
        "Offer Meeting": percentages?.offer_meeting || 0,
        "Training Insights": percentages?.training_insights || 0,
        "Training Milestones": percentages?.training_milestones || 0,
    };

    return {name: stage, value: mapping[stage]};
};


const exportToCSV = (sources, filename) => {
    const headers = [
        'Full Name', 'Source', 'Status', 'Date Job Ad Started', 'Date Hired', 'Date of Application',
        'Stage Scores', 'Total Activities', 'Total Calls', 'Total Texts', 'Total Emails',
        'Phone', 'Email', 'Link'
    ];

    const csvRows = sources.map(source => [
        source.fullName,
        source.source,
        source.status,
        source.dateAdStarted,
        source.dateHired,
        source.dateApplication,
        source.stageScores.length > 1 ? `"${source.stageScores.join('\n')}"` : source.stageScores.join(', '),
        source.activities,
        source.calls,
        source.texts,
        source.emails,
        source.phone,
        source.email,
        // source.link
    ].join(','));

    const csvContent = [headers.join(','), ...csvRows].join('\n');

    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const SBM04_1_1 = () => {
    const dispatch = useDispatch();
    const {jobStats, companyJobs, isLoading} = useSelector(state => state.jobs);
    const {job} = useSelector(state => state.jobs);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState();
    const user = useSelector(getUser);

    const [formData, setFormData] = useState({
        jobId: null,
        start: null,
        end: null,
    });

    useEffect(() => {
        if (companyJobs?.length && !searchParams.get('jobId')) {
            setSearchParams({jobId: companyJobs?.[0]?.id || 9999});
            setFormData(prevState => ({...prevState, jobId: companyJobs?.[0]?.id || 9999}));
        }
    }, [companyJobs]);

    useEffect(() => {
        if (!user?.companies?.length || !user?.companies[0]?.company?.id) return;
        dispatch(getJobsByCompanyFetch({companyId: user.companies[0].company.id, filters: "limit=1000"}));
    }, [user]);

    const getData = async () => {
        setLoading(true)
        const jobId = searchParams.get('jobId') || "18";
        const start = searchParams.get('start');
        const end = searchParams.get('end');
        if (!jobId) return

        if (!formData?.jobId) {
            setFormData(prevState => ({...prevState, jobId: jobId}));
        }
        if (start && end) {
            setFormData(prevState => ({...prevState, start: start, end: end}));
        }

        const data = {jobId};

        if (start && end) {
            data.filters = `start=${start}&end=${end}&mode=pipeline_summary`;
        } else {
            data.filters = "&mode=pipeline_summary"
        }
        await dispatch(getJobStats(data));
        await dispatch(getJobByIdFetch(jobId));

        setLoading(false)
    };

    useEffect(() => {
        if (!searchParams) return;
        getData();
    }, [searchParams]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));

        if (name === "jobId") {
            setSearchParams(prev => {
                const newParams = new URLSearchParams(prev);
                newParams.set('jobId', value);
                return newParams;
            });
        }
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setFormData(prevState => ({...prevState, start, end}));

        if (start && end) {
            const endDateInclusive = new Date(end);
            endDateInclusive.setDate(endDateInclusive.getDate());

            setSearchParams(prev => {
                const newParams = new URLSearchParams(prev);
                newParams.set('start', start.toISOString());
                newParams.set('end', endDateInclusive.toISOString());
                return newParams;
            });
        }
    };

    console.log("jobStats",jobStats)

    const sources = jobStats?.jobApplications?.map((stat, idx) => {
        return {
            fullName: stat?.fullName,
            source: stat?.source === "Carrer Site" ? "https://employjoy.ai" : stat?.source,
            status: stat?.status,
            dateAdStarted: job?.createdAt ? format(job.createdAt, "MM/dd/yyyy") : "-",
            dateHired: stat?.offerMeeting?.date ? format(stat?.offerMeeting?.date, "MM/dd/yyyy") : "-",
            dateApplication: stat.createdAt ? format(stat.createdAt, "MM/dd/yyyy") : "-",
            stageScores: stages
                .map(stage => percentageMapping(stat._progressPipeline, stage))
                .filter(one => !!one.value)
                .map(one => ` ${one.name}: ${one.value}`),
            activities: stat?.countsStat?.sms + stat?.countsStat?.calls + stat?.countsStat?.emails + stat?.countsStat?.aiAgentMessages,
            calls: stat?.countsStat?.calls,
            texts: stat?.countsStat?.aiAgentMessages,
            emails: stat?.countsStat?.emails,
            phone: stat?.phone,
            email: stat?.email,
            // link: `https://ej.webstaginghub.com/SBM01_10_14(2)/${stat.jobId}/${stat.id}`
            // link: `${process.env.REACT_APP_API_URL_CLEAR}/SBM01_10_14(2)/${stat.jobId}/${stat.id}`
        };
    });

    console.log("sources", sources)


    const handleSubmit = (e) => {
        e.preventDefault();
        exportToCSV(sources, `job_stats_${format(formData?.start || new Date(), "MM/dd/yyyy")}-${format(formData?.end || new Date(), "MM/dd/yyyy")}`);
    };

    return (
        <>
            <BreadCrumbWhite crumbs={['Insights', ['Insights', `/SBM04?${searchParams.toString()}`], 'Export CSV']}/>
            <section className="flex flex-auto justify-center lg:py-12 w-full text-zinc-800">
                <div className="flex flex-col items-center lg:my-10 mt-9 mb-20 max-w-[1432px] px-4 w-full">
                    <form onSubmit={handleSubmit}
                          className="flex flex-col w-full py-9 px-8 lg:p-16 bg-white rounded-md shadow-def">
                        <div
                            className="lg:text-3xl text-[28px] leading-[36px] font-bold lg:leading-10 text-zinc-800 w-full pb-[18px] border-b-2 border-solid border-green-500 montserrat">
                            Choose your Customers
                        </div>
                        <div
                            className="w-full flex flex-wrap lg:gap-5 justify-between mt-9 lg:mt-11 text-lg max-md:flex-wrap  max-lg:flex-col">
                            <div className="flex flex-col flex-1 max-w-[550px]">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    label="Job"
                                    name="jobId"
                                    id="jobId"
                                    value={formData.jobId}
                                    onChange={handleChange}
                                    options={companyJobs ? companyJobs.map(one => ({
                                        label: one.jobTitle,
                                        value: one.id
                                    })) : []}
                                />
                            </div>
                            <div className="flex flex-col flex-1 max-w-[550px]">
                                <label className="text-[18px] font-bold text-zinc-800 leading-[18px] mb-4">
                                    Time Period
                                </label>
                                <DatePicker
                                    selected={formData.start}
                                    onChange={handleDateChange}
                                    startDate={formData.start}
                                    endDate={formData.end}
                                    dateFormat="MM/dd/yyyy"
                                    selectsRange
                                    className="bg-white rounded-md px-[18px] text-[18px] focus:outline-none h-[54px] w-full focus:ring-green-500 focus:ring-2 rounded-md border border-solid border-zinc-300 p-2"
                                    placeholderText="Select date range"
                                />
                            </div>
                        </div>
                        <PrimaryButton
                            isLoading={loading}
                            type='submit'
                            className="justify-center text-[19px] font-bold uppercase  max-h-[64px] bg-green-500 rounded-md max-md:px-5 px-8 w-min min-w-[236px]"
                            // onClick={handleSubmit}
                        >
                            Download
                        </PrimaryButton>
                    </form>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default SBM04_1_1;
