import React, {useEffect, useState} from 'react'
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import SelectField from "../components/base/SelectField";
import {Footer} from '../components/parts/Footer';
import {ReactComponent as ArrowRight} from "../assets/img/arrow-right.svg";
import {Link, useSearchParams} from 'react-router-dom';
import DatePicker from "react-datepicker";
import {getJobByIdFetch, getJobsByCompanyFetch, getJobStats} from "../redux/thunks/jobsThunks";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../redux/slices/applicationSlice";
import PageLoader from "../components/PageLoader";

const OfferStats = ({totalOffers, totalAccepted}) => (
    <table>
        <thead>
        <tr>
            <th className="w-1/2 relative px-8 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5">
                <div className="flex items-center text-center justify-center">
                    <span>Total # of Offers Sent</span>
                </div>
            </th>
            <th className="w-1/2 relative px-8 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5">
                <div className="flex items-center text-center justify-center">
                    <span>Total # of Offers Accepted</span>
                </div>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td className="text-center px-8 h-20 whitespace-nowrap">{totalOffers}</td>
            <td className="text-center px-8 h-20 whitespace-nowrap">{totalAccepted}</td>
        </tr>
        </tbody>
    </table>
);

const SourceRow = ({rowData, isEven}) => (
    <tr>
        {rowData.map((children, idx) => (
            <td
                key={idx}
                className={`${idx > 0 ? "text-center" : "text-left"} px-4 h-20 whitespace-nowrap border-b border-solid border-zinc-300 ${
                    !isEven ? "bg-neutral-100" : ""
                }`}
            >
                {children}
            </td>
        ))}
    </tr>
);

const DoubleValue = ({value1, value2}) => (
    <div className="flex gap-3 justify-center px-20 py-7 max-md:px-5">
        <div className="font-bold">{value1}%</div>
        <div>({value2})</div>
    </div>
);

const SBM04_4 = () => {
    const dispatch = useDispatch();
    const {jobStats, companyJobs, isLoading} = useSelector(state => state.jobs);
    const user = useSelector(getUser);

    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);


    const [formData, setFormData] = useState({
        jobId: null,
        start: null,
        end: null,
    });

    useEffect(() => {
        if (companyJobs?.length && !searchParams.get('jobId')) {
            setSearchParams({jobId: companyJobs?.[0]?.id || 9999});
            setFormData(prevState => ({...prevState, jobId: companyJobs?.[0]?.id || 9999}));
        }
    }, [companyJobs]);

    const transformJobStatsToSources = (statsArray = []) => {
        const result = {};

        statsArray.forEach((item) => {
            let src = item.source || "Other";
            if (item?.source === "Carrer Site") {
                src = "https://employjoy.ai"
            }

            if (!result[src]) {
                result[src] = {
                    source: src,
                    applicants: 0,
                    movedToCandidate: 0,
                    completedInterviews: 0,
                    hired: 0,
                    offers: 0,
                    sign: 0
                };
            }

            result[src].applicants += 1;

            if (item.status === 'approved') {
                result[src].movedToCandidate += 1;
            }

            if (
                item?._progressPipeline?.["1st-interview"] &&
                item?._progressPipeline?.["2nd-interview"] &&
                item?._progressPipeline?.["clarifications"] &&
                item?._progressPipeline?.["post_interview_survey"]
            ) {
                result[src].completedInterviews += 1;
            }

            if (item?.offerMeeting) {
                result[src].offers += 1;
            }
            if (item?.offerMeeting?.signApplicant) {
                result[src].sign += 1;
            }
        });

        return Object.values(result).map((sourceObj) => {
            const {applicants, movedToCandidate, completedInterviews, offers, sign} = sourceObj;

            const movedToCandidatePerc = applicants > 0 ? Math.round((movedToCandidate / applicants) * 100) : 0;
            const completedInterviewsPerc = applicants > 0 ? Math.round((completedInterviews / movedToCandidate) * 100) : 0;
            const hiredPerc = applicants > 0 ? Math.round((sign / completedInterviews) * 100) : 0;
            const offersPerc = applicants > 0 ? Math.round((offers / completedInterviews) * 100) : 0;
            const signPerc = offers > 0 ? Math.round((sign / offers) * 100) : 0;

            return {
                source: sourceObj.source,
                applicants,
                movedToCandidate: {percentage: movedToCandidatePerc || 0, value: movedToCandidate},
                completedInterviews: {percentage: completedInterviewsPerc || 0, value: completedInterviews},
                hired: {percentage: hiredPerc || 0, value: sign},
                offers: {percentage: offersPerc || 0, value: offers},
                sign: {percentage: signPerc || 0, value: sign},
            };
        });
    };


    const [sourcesData, setSourcesData] = useState([]);

    useEffect(() => {
        const urlJobId = searchParams.get('jobId');
        const urlStart = searchParams.get('start');
        const urlEnd = searchParams.get('end');

        setFormData(prevState => ({
            ...prevState,
            jobId: urlJobId || prevState.jobId,
            start: urlStart ? new Date(urlStart) : null,
            end: urlEnd ? new Date(urlEnd) : null,
        }));
    }, [searchParams]);

    useEffect(() => {
        if (!user?.companies?.length || !user?.companies[0]?.company?.id) return;
        dispatch(getJobsByCompanyFetch({companyId: user.companies[0].company.id, filters: "limit=1000"}));
    }, [user, dispatch]);

    const getData = async () => {
        setLoading(true);
        const jobId = searchParams.get('jobId') || "18";
        const start = searchParams.get('start');
        const end = searchParams.get('end');
        if (!jobId) return

        if (!formData?.jobId) {
            setFormData(prevState => ({...prevState, jobId: jobId}));
        }
        if (start && end) {
            setFormData(prevState => ({...prevState, start: start, end: end}));
        }

        const data = {jobId};

        if (start && end) {
            data.filters = `start=${start}&end=${end}&mode=pipeline_summary`;
        } else {
            data.filters = "&mode=pipeline_summary"
        }
        await dispatch(getJobStats(data));
        await dispatch(getJobByIdFetch(jobId));

        setLoading(false);
    };

    useEffect(() => {
        if (!searchParams) return;
        getData();
    }, [searchParams]);

    useEffect(() => {
        const transformed = transformJobStatsToSources(jobStats?.jobApplications);
        setSourcesData(transformed);
    }, [jobStats]);


    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));

        if (name === "jobId") {
            setSearchParams(prev => {
                const newParams = new URLSearchParams(prev);
                newParams.set('jobId', value);
                return newParams;
            });
        }
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setFormData(prevState => ({...prevState, start, end}));

        if (start && end) {
            const endDateInclusive = new Date(end);

            setSearchParams(prev => {
                const newParams = new URLSearchParams(prev);
                newParams.set('start', start.toISOString());
                newParams.set('end', endDateInclusive.toISOString());
                return newParams;
            });
        }
    };


    if (loading) {
        return <PageLoader/>;
    }

    return (
        <>
            <BreadCrumbWhite
                crumbs={[
                    'Subscriber Dashboard',
                    ['Insights', `/SBM04?${searchParams.toString()}`],
                    'Offers'
                ]}
            />

            <section className="flex justify-center flex-auto py-9 lg:py-11 w-full text-zinc-800">
                <div className="flex flex-col gap-9 lg:gap-11 items-center w-full max-w-[1432px] px-4">
                    <div className="flex flex-col w-full">
                        <div
                            className="w-full flex max-md:flex-col gap-3 lg:gap-5 justify-between text-lg max-md:flex-wrap">
                            <div className="flex flex-col flex-1 max-w-[550px]">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300 max-h-[100px]"
                                    label="Job"
                                    name="jobId"
                                    id="jobId"
                                    value={formData.jobId}
                                    onChange={handleChange}
                                    options={
                                        companyJobs
                                            ? companyJobs.map((one) => ({
                                                label: one.jobTitle,
                                                value: one.id,
                                            }))
                                            : []
                                    }
                                />
                            </div>
                            <div className="flex flex-col flex-1 max-w-[550px]">
                                <label className="text-[18px] font-bold text-zinc-800 leading-[18px] mb-4">
                                    Time Period
                                </label>
                                <DatePicker
                                    selected={formData.start}
                                    onChange={handleDateChange}
                                    dateFormat="MM/dd/yyyy"
                                    startDate={formData.start}
                                    endDate={formData.end}
                                    selectsRange
                                    className="bg-white rounded-md px-[18px] text-[18px] focus:outline-none h-[54px] w-full focus:ring-green-500 focus:ring-2 border border-solid border-zinc-300 p-2"
                                    placeholderText="Select date range"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col justify-center w-full">
                        <h1 className="w-full text-3xl text-zinc-800 max-md:max-w-full">
                            Career Site ROI
                        </h1>
                        <div
                            className="flex flex-col overflow-hidden justify-center mt-6 w-full text-lg rounded-md border border-solid border-zinc-300 text-neutral-400 max-md:max-w-full">
                            <OfferStats
                                totalOffers={sourcesData.reduce((acc, item) => acc + item?.offers.value || 0, 0)}
                                totalAccepted={sourcesData.reduce((acc, item) => acc + item?.sign.value || 0, 0)}/>
                        </div>
                    </div>

                    <div className="flex flex-col justify-center w-full">
                        <div className="flex gap-5 justify-between w-full max-md:flex-wrap max-md:max-w-full">
                            <div className="flex-auto my-auto text-3xl text-zinc-800">
                                Conversion by Sources
                            </div>
                            <Link
                                to={"/SBM04_1_1"}
                                className="flex gap-2 items-center w-full lg:max-w-[150px] justify-center px-8 py-2 h-[54px] text-lg font-semibold text-center whitespace-nowrap bg-white rounded-md border border-solid border-zinc-300 text-stone-500 max-md:px-5"
                            >
                                <span className="flex items-center justify-center">
                                    <svg
                                        width="25"
                                        height="24"
                                        viewBox="0 0 25 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_268_2595)">
                                            <path
                                                d="M21.5 14.25V19.5C21.5 19.8978 21.342 20.2794 21.0607 20.5607C20.7794 20.842 20.3978 21 20 21H5C4.60218 21 4.22064 20.842 3.93934 20.5607C3.65804 20.2794 3.5 19.8978 3.5 19.5V14.25C3.5 14.0511 3.57902 13.8603 3.71967 13.7197C3.86032 13.579 4.05109 13.5 4.25 13.5C4.44891 13.5 4.63968 13.579 4.78033 13.7197C4.92098 13.8603 5 14.0511 5 14.25V19.5H20V14.25C20 14.0511 20.079 13.8603 20.2197 13.7197C20.3603 13.579 20.5511 13.5 20.75 13.5C20.9489 13.5 21.1397 13.579 21.2803 13.7197C21.421 13.8603 21.5 14.0511 21.5 14.25ZM11.9694 14.7806C12.039 14.8504 12.1217 14.9057 12.2128 14.9434C12.3038 14.9812 12.4014 15.0006 12.5 15.0006C12.5986 15.0006 12.6962 14.9812 12.7872 14.9434C12.8783 14.9057 12.961 14.8504 13.0306 14.7806L16.7806 11.0306C16.8856 10.9257 16.9572 10.792 16.9861 10.6465C17.0151 10.5009 17.0003 10.35 16.9435 10.2129C16.8867 10.0758 16.7904 9.95861 16.667 9.87621C16.5435 9.79381 16.3984 9.74988 16.25 9.75H13.25V3.75C13.25 3.55109 13.171 3.36032 13.0303 3.21967C12.8897 3.07902 12.6989 3 12.5 3C12.3011 3 12.1103 3.07902 11.9697 3.21967C11.829 3.36032 11.75 3.55109 11.75 3.75V9.75H8.75C8.60158 9.74988 8.45646 9.79381 8.33301 9.87621C8.20957 9.95861 8.11335 10.0758 8.05653 10.2129C7.99972 10.35 7.98487 10.5009 8.01385 10.6465C8.04284 10.792 8.11437 10.9257 8.21937 11.0306L11.9694 14.7806Z"
                                                fill="#37B34A"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_268_2595">
                                                <rect
                                                    width="24"
                                                    height="24"
                                                    fill="white"
                                                    transform="translate(0.5)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <span>Export</span>
                            </Link>
                        </div>
                        <div
                            className="flex flex-col overflow-auto justify-center mt-6 w-full text-lg rounded-md border border-solid border-zinc-300 text-neutral-400 max-md:max-w-full">
                            <table className="w-full">
                                <thead>
                                <tr className="w-full">
                                    <th className="px-8 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 text-left max-md:px-5">
                                        Source
                                    </th>
                                    <th className="px-8 h-[90px] min-w-[180px] w-[250px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 text-center max-md:px-5">
                                        <div className="flex items-center justify-center">
                                            Applicants
                                            <span className="absolute right-0 z-[1] translate-x-2/4 w-10 aspect-[2.22]">
                                                <ArrowRight/>
                                            </span>
                                        </div>
                                    </th>
                                    <th className="px-8 h-[90px] min-w-[180px] w-[250px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 text-center max-md:px-5">
                                        <div className="flex items-center justify-center">
                                            Moved to Candidate
                                            <span className="absolute right-0 z-[1] translate-x-2/4 w-10 aspect-[2.22]">
                                                <ArrowRight/>
                                            </span>
                                        </div>
                                    </th>
                                    <th className="px-8 h-[90px] min-w-[180px] w-[250px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 text-center max-md:px-5">
                                        <div className="flex items-center justify-center">
                                            Completed Interviews
                                            <span className="absolute right-0 z-[1] translate-x-2/4 w-10 aspect-[2.22]">
                                                <ArrowRight/>
                                            </span>
                                        </div>
                                    </th>
                                    <th className="px-8 h-[90px] min-w-[180px] w-[250px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 text-center max-md:px-5">
                                        <div className="flex items-center justify-center">
                                            Total # of Offers Sent
                                            <span className="absolute right-0 z-[1] translate-x-2/4 w-10 aspect-[2.22]">
                                                <ArrowRight/>
                                            </span>
                                        </div>
                                    </th>
                                    <th className="px-8 h-[90px] min-w-[180px] w-[250px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 text-center max-md:px-5">
                                        Total # of Offers Accepted
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {sourcesData.map((source, idx) => (
                                    <SourceRow
                                        key={idx}
                                        rowData={[
                                            source.source,
                                            source.applicants,
                                            <DoubleValue
                                                value1={source.movedToCandidate.percentage}
                                                value2={source.movedToCandidate.value}
                                            />,
                                            <DoubleValue
                                                value1={source.completedInterviews.percentage}
                                                value2={source.completedInterviews.value}
                                            />,
                                            <DoubleValue
                                                value1={source.offers.percentage}
                                                value2={source.offers.value}
                                            />,
                                            <DoubleValue
                                                value1={source.sign.percentage}
                                                value2={source.sign.value}
                                            />,
                                        ]}
                                        isEven={idx % 2 === 0}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SBM04_4;
