import React, {useEffect, useState} from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import {Footer} from '../components/parts/Footer';
import MilestoneBar from "../components/parts/MilestoneVisualization";
import {Link, useLocation, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {fetchAllIndustries} from "../redux/thunks/companiesThunks";
import {getJobByIdFetch, getJobsByCompanyFetch, getJobStats} from "../redux/thunks/jobsThunks";
import {getUser} from "../redux/slices/applicationSlice";
import PageLoader from "../components/PageLoader";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {format} from "date-fns";

const Milestone = ({colorClass, text, imgSrc, imgAlt}) => (
    <div className="flex items-center gap-3 py-1.5">
        <div className={`shrink-0 w-3 h-3 ${colorClass} rounded-full`}/>
        <div className="flex-auto font-semibold">{text}</div>
        {
            imgSrc && <img src={imgSrc} alt={imgAlt} className="object-cover absolute inset-0 size-full"/>
        }
    </div>
);

function TimeToHireReport({averageTimeToHire = 0}) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const start = searchParams.get('start') || '';
    const end = searchParams.get('end') || '';
    const jobId = searchParams.get('jobId') || '';

    const queryString = new URLSearchParams({start, end, jobId}).toString();
    return (
        <div
            className="flex flex-col max-lg:items-center max-lg:text-center grow px-8 py-10 rounded-md border border-solid border-zinc-300 text-stone-500 max-md:px-5 max-md:max-w-full">
            <h2 className="text-2xl max-md:max-w-full">Time to Hire Report</h2>
            <div className="flex flex-col px-7 mt-11 max-md:px-5 max-md:mt-10">
                <div className="text-4xl leading-8">{averageTimeToHire}d</div>
                <div className="mt-4 text-xl">Avg Time</div>
            </div>
            <p className="mt-11 text-base leading-7 max-md:mt-10 max-md:max-w-full">
                <span className="font-bold">Time to hire (TtH)</span> is a key metric that measures the time it takes to
                hire a candidate for a position. How fast you can fill a position is an important indicator for hiring
                effectiveness.
            </p>
            <Link to={`/SBM04_3?${queryString}`}
                  className="mt-11 text-base font-semibold text-green-500 max-md:mt-10 max-md:max-w-full">
                View Hiring Velocity Insight
            </Link>
        </div>
    );
}


function OffersReport({total, queryString}) {
    return (
        <Link to={`/SBM04_4?${queryString}`}
              className="flex flex-col justify-center max-lg:items-center max-lg:text-center px-8 py-8 rounded-md border border-solid border-zinc-300 max-md:px-5 max-md:max-w-full">
            <div className="flex gap-5 items-center max-md:flex-wrap">
                <h2 className="grow self-start text-2xl">Offers</h2>
                <div className="flex flex-auto max-lg:items-center max-lg:text-center gap-5 max-lg:flex-col">
                    <div className="flex flex-col text-center px-7 flex-shrink-0 my-auto max-md:px-5">
                        <div className="text-4xl leading-8">{total}</div>
                        <div className="mt-4 text-xl">Total Hires</div>
                    </div>
                    <p className="flex-auto text-base leading-7 max-md:max-w-full">
                        <span className="font-bold">Offers</span> is a key performance metric that tracks the total
                        number of job offers extended for a specific position within a defined timeframe. It provides
                        insight into the recruitment process, helping to assess the effectiveness of sourcing and
                        candidate engagement strategies
                    </p>
                </div>
            </div>
        </Link>
    );
}

function ApplicantVolumeReport({total, queryString}) {
    return (
        <div
            className="flex justify-center max-lg:items-center max-lg:text-center px-16 py-8 mt-7 rounded-md border border-solid border-zinc-300 max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col items-center w-full lg:max-w-[311px]">
                <h2 className="text-2xl">Applicant Volume & Source</h2>
                <div className="flex flex-col px-14 mx-5 mt-7 max-md:px-5 max-md:mx-2.5">
                    <div className="self-center text-4xl leading-8">{total}</div>
                    <div className="mt-4 text-xl">Quality Applicants</div>
                </div>
                <Link to={`/SBM04_2?${queryString}`}
                      className="self-center mt-7 text-base font-semibold text-green-500">
                    View Applicant Sourcing Insight
                </Link>
            </div>
        </div>
    );
}

function HiringInsightsReport({jobStats, queryString, total}) {
    const sources = jobStats?.map((stat) => {
        const dateApplication = new Date(stat.createdAt);
        const dateHired = stat?.offerMeeting?.date ? new Date(stat?.offerMeeting?.date) : null;

        return dateHired ? Math.ceil((dateApplication - dateHired) / (1000 * 60 * 60 * 24)) : null;
    });
    const filteredSources = sources?.filter((date) => date !== null);
    const averageTimeToHire = filteredSources?.length
        ? (filteredSources?.reduce((sum, days) => sum + days, 0) / filteredSources?.length).toFixed(0)
        : 0;

    return (
        <div className="flex lg:gap-7 gap-5 max-lg:flex-col">
            <div className="flex flex-col max-lg:w-full lg:w-2/5">
                <TimeToHireReport averageTimeToHire={averageTimeToHire}/>
            </div>
            <div className="flex flex-col lg:gap-7 gap-5 max-lg:w-full lg:w-3/5">
                <div className="flex flex-col grow self-stretch text-stone-500">
                    <OffersReport total={jobStats?.filter(one => !!one?.offerMeeting?.signApplicant).length || 0}
                                  queryString={queryString}/>
                    <ApplicantVolumeReport total={total} queryString={queryString}/>
                </div>
            </div>
        </div>
    );
}

export function getValueByPath(obj, path) {
    return path.split('.')?.reduce((acc, key) => acc && acc[key] !== undefined ? acc[key] : undefined, obj);
}

export function isEmptyValue(value) {
    if (value === null || value === undefined) return true;
    if (Array.isArray(value)) return value.length === 0;
    if (typeof value === 'object') return Object.keys(value).length === 0;
    if (value === '') return true;
    return false;
}

export function getCandidateCurrentStep(candidate, milestones) {
    let currentStep = null;
    for (let i = 0; i < milestones.length; i++) {
        const milestone = milestones[i];
        const value = getValueByPath(candidate, milestone.stageKey);
        if (isEmptyValue(value)) {
            currentStep = milestone;
            break;
        } else {
            currentStep = milestone;
        }
    }
    return currentStep;
}

export function getMilestonesStats(candidates, milestones) {
    const total = candidates?.length;
    const counts = {};
    milestones.forEach(m => {
        counts[m.text] = 0;
    });

    candidates.forEach(candidate => {
        const currentStep = getCandidateCurrentStep(candidate, milestones);
        if (currentStep) {
            counts[currentStep.text] = (counts[currentStep.text] || 0) + 1;
        }
    });

    const result = {};
    milestones.forEach(m => {
        const count = counts[m.text];
        const percentage = count ? (count / total).toFixed(3) * 100 : 0;
        result[m.text] = {
            colorClass: m.colorClass,
            text: m.text,
            count: count,
            percentage: percentage
        }
    });
    return result;
}


const SBM04 = () => {
    const dispatch = useDispatch();
    const {jobStats, companyJobs, isLoading} = useSelector(state => state.jobs);
    const {job} = useSelector(state => state.jobs)
    const [loading, setLoading] = useState();
    const user = useSelector(getUser);
    const [searchParams, setSearchParams] = useSearchParams();

    const [formData, setFormData] = useState({
        jobId: null,
        start: null,
        end: null,
    });

    useEffect(() => {
        if (companyJobs?.length && !searchParams.get('jobId')) {
            setSearchParams({jobId: companyJobs?.[0]?.id || 9999});
            setFormData(prevState => ({...prevState, jobId: companyJobs?.[0]?.id || 9999}));
        }
    }, [companyJobs]);

    const getData = async () => {
        setLoading(true)
        const jobId = searchParams.get('jobId');
        const start = searchParams.get('start');

        console.log("formData", formData)
        const end = searchParams.get('end');
        if (!jobId) return
        if (!formData?.jobId) {
            setFormData(prevState => ({...prevState, jobId: jobId}));
        }
        if (start && end) {
            setFormData(prevState => ({...prevState, start: start, end: end}));
        }

        const data = {jobId};

        if (start && end) {
            data.filters = `start=${start}&end=${end}&mode=pipeline_summary`;
        } else {
            data.filters = "&mode=pipeline_summary"
        }
        await dispatch(getJobStats(data));
        await dispatch(getJobByIdFetch(jobId));

        setLoading(false)
    };
    useEffect(() => {
        if (!searchParams) return;
        getData();
    }, [searchParams]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
        setSearchParams(prev => {
            prev.set(name, value);
            return prev;
        })
    };

    useEffect(() => {
        if (!user?.companies?.length || !user?.companies[0]?.company?.id) return;
        dispatch(getJobsByCompanyFetch({companyId: user.companies[0].company.id, filters: "limit=1000"}));
    }, [user]);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setFormData(prevState => ({...prevState, start, end}));
        if (start && end) {
            const endDateInclusive = new Date(end);
            endDateInclusive.setDate(endDateInclusive.getDate());

            setSearchParams(prev => {
                prev.set('start', start.toISOString());
                prev.set('end', endDateInclusive.toISOString());
                return prev;
            });
        }
    };


    const milestones = [
        {colorClass: "bg-rose-800", text: "Applicants", stageKey: 'assessments.clarifications'},
        {colorClass: "bg-rose-600", text: "Candidates", stageKey: 'assessments.clarifications'},
        {colorClass: "bg-rose-400", text: "Clarification", stageKey: 'assessments.clarifications'},
        {colorClass: "bg-red-300", text: "1st Interview", stageKey: 'assessments.1st-interview'},
        {
            colorClass: "bg-orange-400",
            text: "Post Interview Survey",
            stageKey: 'assessments.post_interview_survey'
        },
        {colorClass: "bg-orange-300", text: "2nd Interview", stageKey: 'assessments.2nd-interview'},
        {colorClass: "bg-amber-200", text: "Doc Review", stageKey: 'doc_review'},
        {colorClass: "bg-yellow-100", text: "Rating of the Job", stageKey: 'assessments.recuterInput'},
        {colorClass: "bg-lime-200", text: "AI Evaluation", stageKey: 'ai_evaluation'},
        {colorClass: "bg-lime-300", text: "Final Hiring Decisions", stageKey: 'assessments.final-hiring'},
        {colorClass: "bg-green-600", text: "Offer Meeting", stageKey: 'offerMeeting'},
        {colorClass: "bg-emerald-800", text: "Training Starts", stageKey: 'trainingMilestones'},
    ];

    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Insights']}/>
            <section className="flex flex-auto justify-center py-9 lg:py-11 w-full text-zinc-800">
                <div className="flex flex-col gap-9 lg:gap-11 items-center w-full max-w-[1432px] px-4">
                    <div className="flex flex-col w-full">
                        <div
                            className="w-full flex max-md:flex-col gap-3 lg:gap-5 justify-between text-lg max-md:flex-wrap">
                            <div className="flex flex-col flex-1 max-w-[550px]">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300 max-h-[100px]"
                                    label="Job"
                                    name="jobId"
                                    id="jobId"
                                    value={formData?.jobId || companyJobs?.[0]?.id}
                                    onChange={handleChange}
                                    options={companyJobs ? companyJobs.map(one => ({
                                        label: one.jobTitle,
                                        value: one.id
                                    })) : []}
                                />
                            </div>
                            <div className="flex flex-col flex-1 max-w-[550px]">
                                <label className="text-[18px] font-bold text-zinc-800 leading-[18px] mb-4">
                                    Time Period
                                </label>
                                <DatePicker
                                    selected={formData?.start}
                                    onChange={handleDateChange}
                                    dateFormat="MM/dd/yyyy"
                                    startDate={formData?.start}
                                    endDate={formData?.end}
                                    selectsRange
                                    className="bg-white rounded-md px-[18px] text-[18px] focus:outline-none h-[54px] w-full focus:ring-green-500 focus:ring-2   rounded-md border border-solid border-zinc-300 p-2"
                                    placeholderText="Select date range"
                                />
                            </div>
                        </div>
                    </div>
                    {loading ? <PageLoader/> :
                        <>
                            <HiringInsightsReport jobStats={jobStats?.jobApplications}
                                                  total={jobStats?._applicants?.count || 0}
                                                  queryString={searchParams.toString()}/>
                            <div className="flex flex-col justify-center w-full">
                                <header
                                    className="flex gap-5 justify-between p-9 lg:px-11 lg:py-14 w-full font-bold bg-neutral-100 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                                    <h1 className="flex-auto my-auto lg:text-3xl lg:leading-10 text-[28px] leading-9 text-green-500">
                                        Pipeline Summary
                                    </h1>
                                    <div className="flex flex-col text-lg text-stone-500">
                                        <p>Opened:<span> {job?.createdAt ? format(job?.createdAt, "MM/dd/yyyy") : "-"}</span>
                                        </p>
                                        <p className="lg:mt-5">Status: <span>Open</span></p>
                                    </div>
                                </header>
                                <section
                                    className="justify-center lg:px-10 max-lg:mt-9 lg:py-10 py-9 px-8 w-full border-r border-b border-l max-lg:border-t border-solid border-[#DEDED9]">
                                    <div className="flex gap-5 max-lg:flex-col">
                                        <div className="flex flex-col w-full lg:w-[27%]">
                                            <div className="grow">
                                                {milestones.map((milestone, index) => (
                                                    <Milestone key={index} {...milestone}/>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex flex-auto">
                                            <div
                                                className="flex flex-auto grow justify-center items-center text-lg font-bold text-white whitespace-nowrap max-md:max-w-full">
                                                <MilestoneBar data={{
                                                    ...jobStats?.stats,
                                                    // offer: jobStats?.jobApplications?.filter(one => !!one?.offerMeeting?.signApplicant)?.length,
                                                    application: {
                                                        ...jobStats?._applicants,
                                                        procentage: !!jobStats?._applicants?.count ? 100 : 0
                                                    },
                                                    candidates: jobStats?._candidates
                                                }}/>
                                            </div>
                                        </div>
                                        <div className='flex items-start lg:justify-end lg:w-[27%]'>
                                            <Link to={`/SBM04_1_1?${searchParams.toString()}`}
                                                  className="flex items-center justify-center w-full lg:max-w-[236px] px-4 lg:h-16 h-[60px] mt-5 lg:mt-11 lg:text-[22px] text-[19px] font-bold text-center text-white uppercase whitespace-nowrap bg-green-500 rounded-md">
                                                Export CSV
                                            </Link>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </>
                    }
                </div>
            </section>
            {/*<Footer/>*/}
        </>
    );
};

export default SBM04;
