import React, {useEffect, useState} from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import SelectField from "../components/base/SelectField";
import {Footer} from '../components/parts/Footer';
import {Link, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getJobByIdFetch, getJobsByCompanyFetch, getJobStats} from "../redux/thunks/jobsThunks";
import {getUser} from "../redux/slices/applicationSlice";
import DatePicker from "react-datepicker";
import PageLoader from "../components/PageLoader";
import {format} from "date-fns";


const SourceRow = ({rowData, isEven}) => (
    <tr>
        {rowData.map((children, idx) => (
            <td
                key={idx}
                className={`text-center px-6 h-20 whitespace-nowrap border-b border-solid border-zinc-300 ${!isEven ? "bg-neutral-100" : ""}`}
            >
                {children}
            </td>
        ))}
    </tr>
);


const exportToCSV = (sources, filename) => {
    const headers = ['Date Job Ad Started', 'Date Hired', 'Date of Application', 'Time to Hire (days)', 'Total Activities', 'Total Calls', 'Total Texts', 'Total Emails'];

    const csvRows = sources.map(source => [
        source.dateAdStarted,
        source.dateHired,
        source.dateApplication,
        source.daysToHire,
        source.activities,
        source.calls,
        source.texts,
        source.emails
    ].join(','));

    const csvContent = [headers.join(','), ...csvRows].join('\n');

    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const SBM04_3 = () => {
    const dispatch = useDispatch();
    const {jobStats, companyJobs, isLoading} = useSelector(state => state.jobs);
    const {job} = useSelector(state => state.jobs)
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const user = useSelector(getUser);
    const [formData, setFormData] = useState({
        jobId: null,
        start: null,
        end: null,
    });

    useEffect(() => {
        if (companyJobs?.length && !searchParams.get('jobId')) {
            setSearchParams({jobId: companyJobs?.[0]?.id || 9999});
            setFormData(prevState => ({...prevState, jobId: companyJobs?.[0]?.id || 9999}));
        }
    }, [companyJobs]);

    useEffect(() => {
        if (!user?.companies?.length || !user?.companies[0]?.company?.id) return;
        dispatch(getJobsByCompanyFetch({companyId: user.companies[0].company.id, filters: "limit=1000"}));
    }, [user]);

    const getData = async () => {
        setLoading(true);
        const jobId = searchParams.get('jobId') || "18";
        const start = searchParams.get('start');
        const end = searchParams.get('end');
        if (!jobId) return

        if (!formData?.jobId) {
            setFormData(prevState => ({...prevState, jobId: jobId}));
        }
        if (start && end) {
            setFormData(prevState => ({...prevState, start: start, end: end}));
        }

        const data = {jobId};

        if (start && end) {
            data.filters = `start=${start}&end=${end}&mode=pipeline_summary`;
        } else {
            data.filters = "&mode=pipeline_summary"
        }
        await dispatch(getJobStats(data));
        await dispatch(getJobByIdFetch(jobId));

        setLoading(false);
    };

    useEffect(() => {
        if (!searchParams) return;
        getData();
    }, [searchParams]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));

        if (name === "jobId") {
            setSearchParams(prev => {
                const newParams = new URLSearchParams(prev);
                newParams.set('jobId', value);
                return newParams;
            });
        }
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setFormData(prevState => ({...prevState, start, end}));

        if (start && end) {
            const endDateInclusive = new Date(end);
            endDateInclusive.setDate(endDateInclusive.getDate());

            setSearchParams(prev => {
                const newParams = new URLSearchParams(prev);
                newParams.set('start', start.toISOString());
                newParams.set('end', endDateInclusive.toISOString());
                return newParams;
            });
        }
    };

    const sources = jobStats?.jobApplications?.map((stat, idx) => {
        const dateApplication = new Date(stat?.createdAt);
        const dateHired = stat?.offerMeeting?.date ? new Date(stat?.offerMeeting?.date) : null;

        const daysToHire = dateHired ? Math.ceil((dateApplication - dateHired) / (1000 * 60 * 60 * 24)) : "-";

        return {
            dateAdStarted: job?.createdAt ? format(job?.createdAt, "MM/dd/yyyy") : "-",
            dateHired: dateHired ? format(dateHired, "MM/dd/yyyy") : "-",
            dateApplication: dateApplication ? format(dateApplication, "MM/dd/yyyy") : "-",
            daysToHire,
            activities: stat?.countsStat?.sms + stat?.countsStat?.calls + stat?.countsStat?.emails + stat?.countsStat?.aiAgentMessages,
            calls: stat?.countsStat?.calls,
            texts: stat?.countsStat?.aiAgentMessages,
            emails: stat?.countsStat?.emails
        };
    });


    return (
        <>
            <BreadCrumbWhite
                crumbs={['Subscriber Dashboard', ['Insights', `/SBM04?${searchParams.toString()}`], 'Time to Hire Report']}/>
            <section className="flex justify-center flex-auto py-9 lg:py-11 w-full text-zinc-800">
                <div className='flex flex-col gap-11 items-center w-full max-w-[1432px] px-4'>
                    <div className="flex flex-col w-full">
                        <div className='flex flex-col-reverse lg:flex-col w-full'>
                            <button
                                onClick={() => exportToCSV(sources, `Time_to_Hire_Report_${format(formData?.start || new Date(), "dd-MM-yyyy")}-${format(formData?.end || new Date(), "dd-MM-yyyy")}`)}
                                className='h-[60px] py-1 max-lg:text-[19px] w-full lg:max-w-[186px] mb-[26px] self-end flex justify-center items-center lg:text-[1.375rem] font-bold text-[19px] lg:h-[4.625rem] whitespace-nowrap bg-[#37B34A] rounded-md text-white hover:bg-[#002E67]'
                            >
                                Export CSV
                            </button>
                            <div className="w-full flex max-lg:flex-col lg:gap-5 justify-between text-lg">
                                <div className="flex flex-col flex-1 max-w-[550px]">
                                    <SelectField
                                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                        label="Job"
                                        name="jobId"
                                        id="jobId"
                                        value={formData.jobId}
                                        onChange={handleChange}
                                        options={companyJobs ? companyJobs.map(one => ({
                                            label: one.jobTitle,
                                            value: one.id
                                        })) : []}
                                    />
                                </div>
                                <div className="flex flex-col flex-1 max-w-[550px]">
                                    <label className="text-[18px] font-bold text-zinc-800 leading-[18px] mb-4">
                                        Time Period
                                    </label>
                                    <DatePicker
                                        selected={formData.start}
                                        onChange={handleDateChange}
                                        startDate={formData.start}
                                        endDate={formData.end}
                                        dateFormat="MM/dd/yyyy"
                                        selectsRange
                                        className="bg-white rounded-md px-[18px] text-[18px] focus:outline-none h-[54px] w-full focus:ring-green-500 focus:ring-2 rounded-md border border-solid border-zinc-300 p-2"
                                        placeholderText="Select date range"
                                    />
                                </div>
                            </div>
                        </div>
                        {loading ? <PageLoader/> : (
                            <div
                                className="flex flex-col justify-center mt-6 w-full text-lg rounded-md border border-solid border-zinc-300 text-neutral-400 max-md:max-w-full overflow-auto">
                                <table className="max-w-full overflow-x-scroll whitespace-nowrap">
                                    <thead>
                                    <tr className='w-full'>
                                        <th className="relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5">
                                            <div className="text-center">Date Job Ad Started</div>
                                        </th>
                                        <th className="relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5">
                                            <div className="text-center">Date Hired</div>
                                        </th>
                                        <th className='relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Date of Application</div>
                                        </th>
                                        <th className='relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Time to Hire (days)</div>
                                        </th>
                                        <th className='relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Total Activities</div>
                                        </th>
                                        <th className='relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Total Calls</div>
                                        </th>
                                        <th className='relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Total Texts</div>
                                        </th>
                                        <th className='relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Total Emails</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {sources?.map((source, idx) => (
                                        <SourceRow
                                            key={idx}
                                            rowData={[
                                                source.dateAdStarted,
                                                source.dateHired,
                                                source.dateApplication,
                                                source.daysToHire,
                                                source.activities,
                                                source.calls,
                                                source.texts,
                                                source.emails
                                            ]}
                                            isEven={idx % 2 === 0}
                                        />
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default SBM04_3;
