import React, {useEffect} from "react";
import {Footer} from "../components/parts/Footer";
import {useState} from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import SelectField from "../components/base/SelectField";
import InputField from "../components/base/InputField";
import ExportIcon from "../assets/img/export-icon.svg"
import {Link, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../redux/slices/applicationSlice";
import {getJobByIdFetch, getJobsByCompanyFetch, getJobStats} from "../redux/thunks/jobsThunks";
import DatePicker from "react-datepicker";
import PageLoader from "../components/PageLoader";

const ButtonExport = ({className, ...rest}) => {
    return (
        <Link to={"/SBM04_1_1"} className={`flex gap-2 items-center justify-center px-8 py-1 h-[52px] lg:h-[54px] text-lg font-[600] 
            text-center whitespace-nowrap bg-white rounded-md border border-solid
            border-zinc-300 max-md:px-5 text-[#666666] ${className ? className : ""}`}
              {...rest}
        >
            <img loading="lazy" src={ExportIcon} alt="" className="shrink-0 w-6 aspect-square"/>
            <span className="md:grow md:my-auto">Export</span>
        </Link>
    )
}


const SBM04_2 = () => {
    const dispatch = useDispatch();
    const {jobStats, companyJobs, isLoading} = useSelector(state => state.jobs);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState();
    const [formData, setFormData] = useState({
        jobId: null,
        start: null,
        end: null,
    });
    const user = useSelector(getUser);

    useEffect(() => {
        if (companyJobs?.length && !searchParams.get('jobId')) {
            setSearchParams({jobId: companyJobs?.[0]?.id || 9999});
            setFormData(prevState => ({...prevState, jobId: companyJobs?.[0]?.id || 9999}));
        }
    }, [companyJobs]);

    useEffect(() => {
        if (!user?.companies?.length || !user?.companies[0]?.company?.id) return;
        dispatch(getJobsByCompanyFetch({companyId: user.companies[0].company.id, filters: "limit=1000"}));
    }, [user]);

    const getData = async () => {
        setLoading(true);
        const jobId = searchParams.get('jobId') || "18";
        const start = searchParams.get('start');
        const end = searchParams.get('end');

        const data = {jobId};

        if (!formData?.jobId) {
            setFormData(prevState => ({...prevState, jobId: jobId}));
        }
        if (start && end) {
            setFormData(prevState => ({...prevState, start: start, end: end}));
        }

        if (start && end) {
            data.filters = `start=${start}&end=${end}&mode=pipeline_summary`;
        } else {
            data.filters = "&mode=pipeline_summary"
        }
        await dispatch(getJobStats(data));
        await dispatch(getJobByIdFetch(jobId));

        setLoading(false);
    };

    useEffect(() => {
        if (!searchParams) return;
        getData();
    }, [searchParams]);


    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));

        if (name === "jobId") {
            setSearchParams(prev => {
                const newParams = new URLSearchParams(prev);
                newParams.set('jobId', value);
                return newParams;
            });
        }
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setFormData(prevState => ({...prevState, start, end}));

        if (start && end) {
            const endDateInclusive = new Date(end);
            endDateInclusive.setDate(endDateInclusive.getDate());

            setSearchParams(prev => {
                const newParams = new URLSearchParams(prev);
                newParams.set('start', start.toISOString());
                newParams.set('end', endDateInclusive.toISOString());
                return newParams;
            });
        }
    };

    const getSourcesData = (jobStats) => {
        const total = jobStats?.length || 0;
        const sourceMap = {};

        jobStats?.forEach(candidate => {
            const src = candidate.source === "Carrer Site" ? "https://employjoy.ai" : candidate.source || 'https://employjoy.ai';
            if (!sourceMap[src]) {
                sourceMap[src] = 0;
            }
            sourceMap[src] += 1;
        });

        let sourceData = Object.keys(sourceMap).map(src => {
            const count = sourceMap[src];
            const percentage = total > 0 ? Math.round((count / total) * 100) : 0;
            return {
                sourceName: src,
                count: count,
                persectage: percentage + '%'
            };
        });

        sourceData.sort((a, b) => b.count - a.count);

        return sourceData;
    };

    const sourceData = getSourcesData(jobStats?.jobApplications || []);

    const SourceItem = ({sourceName, count, persectage}) => (
        <div
            className="flex gap-5 justify-between mt-6 max-md:flex-wrap max-md:max-w-full max-md:mt-[16px] max-md:gap-[12px]">
            <div className="my-auto lg:text-right text-stone-500 whitespace-nowrap min-w-[240px]"> {sourceName} </div>
            <div className="flex flex-auto">
                <div
                    className="h-[46px] flex items-center justify-end px-[12px] text-white text-end bg-[#003578] rounded-md max-md:pl-5 max-md:max-w-full"
                    style={{width: persectage,}}
                >
                    {count}
                </div>
            </div>
        </div>
    );

    return (
        <>
            <BreadCrumbWhite
                crumbs={['Subscriber Dashboard', ['Insights', `/SBM04?${searchParams.toString()}`], 'Applicants Volume & Source']}/>
            <section className="flex justify-center flex-auto w-full text-zinc-800">
                <div className="flex flex-col w-full max-w-[1432px] px-4">
                    <div
                        className="w-full flex max-md:flex-col gap-3 lg:gap-5 justify-between text-lg max-md:flex-wrap mt-5">
                        <div className="flex flex-col flex-1 max-w-[550px]">
                            <SelectField
                                inputBg="bg-white rounded-md border border-solid border-zinc-300 max-h-[100px]"
                                label="Job"
                                name="jobId"
                                id="jobId"
                                value={formData?.jobId || companyJobs?.[0]?.id}
                                onChange={handleChange}
                                options={companyJobs ? companyJobs.map(one => ({
                                    label: one.jobTitle,
                                    value: one.id
                                })) : []}
                            />
                        </div>
                        <div className="flex flex-col flex-1 max-w-[550px]">
                            <label className="text-[18px] font-bold text-zinc-800 leading-[18px] mb-4">
                                Time Period
                            </label>
                            <DatePicker
                                selected={formData?.start}
                                onChange={handleDateChange}
                                dateFormat="MM/dd/yyyy"
                                startDate={formData?.start}
                                endDate={formData?.end}
                                selectsRange
                                className="bg-white rounded-md px-[18px] text-[18px] focus:outline-none h-[54px] w-full focus:ring-green-500 focus:ring-2   rounded-md border border-solid border-zinc-300 p-2"
                                placeholderText="Select date range"
                            />
                        </div>
                    </div>
                    <h3 className="text-[28px] font-normal text-start w-full">Which source gives me the most quality
                        applicants?</h3>
                    {!loading && <section
                        className="flex flex-col my-9 lg:my-11 px-8 lg:px-[52px] py-9 lg:py-11 rounded-md border border-solid border-zinc-300">
                        <div className="flex gap-9 max-lg:flex-col">
                            <div className="flex flex-col w-[43%] max-md:ml-0 max-md:w-full">
                                <h2 className="flex-auto my-auto lg:text-[26px] text-2xl mb-8 lg:mb-[52px] text-[#666666]">Quality
                                    Rate</h2>
                                <div className="flex flex-col grow items-start text-stone-500 max-md:max-w-full">
                                    <div className="grid max-lg:grid-cols-2 gap-9">
                                        <div className="flex flex-col text-center items-center whitespace-nowrap">
                                            <div className="text-4xl leading-8">{jobStats?.count || 0}</div>
                                            <h2 className="mt-4 text-xl whitespace-nowrap"> Total Applicants </h2>
                                        </div>
                                        <div className="flex flex-col text-center items-center whitespace-nowrap">
                                            <div className="text-4xl leading-8">{sourceData.length}</div>
                                            <h3 className="mt-4 text-xl">Total Sources</h3>
                                        </div>
                                    </div>
                                    {/*<div className="self-stretch mt-9 text-base leading-7 max-md:max-w-full">*/}
                                    {/*    <b>Lorem ipsum dolor sit amet</b>, consectetur adipiscing elit. Quisque ornare*/}
                                    {/*    scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet,*/}
                                    {/*    bibendum et purus.*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="flex flex-col ml-5 w-[57%] max-md:ml-0 max-md:w-full">
                                <div
                                    className="flex items-center lg:justify-end lg:gap-14 mb-8 lg:mb-[52px] max-md:mb-[8px]">
                                    <div className="lg:text-[26px] text-2xl text-[#666666]"> Total Quality Applicants
                                    </div>
                                    <ButtonExport className={"max-lg:hidden"}/>
                                </div>
                                <div className="flex flex-col grow self-stretch md:pb-11 text-lg max-md:max-w-full">
                                    {sourceData.map((source, index) => (
                                        <SourceItem key={index} sourceName={source.sourceName} count={source.count}
                                                    persectage={source.persectage}/>
                                    ))}
                                    {/*<a href="#"*/}
                                    {/*   className="self-center mt-6 text-base font-semibold text-green-500 whitespace-nowrap hover:opacity-70 max-md:self-start">Show*/}
                                    {/*    top 10 sources</a>*/}
                                    <ButtonExport className={"lg:hidden mt-9"}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    }
                    {loading && <PageLoader/>}
                </div>
            </section>
        </>
    )
}

export default SBM04_2;
