import React, {useEffect, useState} from "react";
import InputGroup from "../../components/base/InputGroup";

const Calculator = () => {
    const [formValues, setFormValues] = useState({
        employeesLeft: "20",
        averageEmployees: "200",
        hiringCosts: "4700",
        revenueCosts: "6000",
        trainingCosts: "1096",
        unfilledTime: "6240",
    });

    const [results, setResults] = useState({
        totalCost: 0,
        turnoverRate: 0,
    });

    const formatCurrency = (value, cents = 0) =>
        new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: cents,
        }).format(value);

    useEffect(() => {
        const parseNumber = (value) => parseFloat(value) || 0;

        const {
            employeesLeft,
            averageEmployees,
            hiringCosts,
            revenueCosts,
            trainingCosts,
            unfilledTime,
        } = formValues;

        const [employeesLeftNum, averageEmployeesNum, hiringCostsNum, revenueCostsNum, trainingCostsNum, unfilledTimeNum] =
            [employeesLeft, averageEmployees, hiringCosts, revenueCosts, trainingCosts, unfilledTime].map(parseNumber);

        const totalCost =
            employeesLeftNum *
            (hiringCostsNum + revenueCostsNum + trainingCostsNum + unfilledTimeNum);
        const turnoverRate = averageEmployeesNum
            ? (employeesLeftNum / averageEmployeesNum) * 100
            : 0;

        setResults({
            totalCost,
            turnoverRate: turnoverRate.toFixed(2),
        });
    }, [formValues]);

    const handleChange = (e) => {
        const {name, value} = e.target;

        if (/^[1-9]\d*$/.test(value) || value === "") {
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
    };

    return (
        <div className="flex items-center justify-center ">
            <div className="calculator wow fadeIn" data-wow-delay={"100ms"}>
                <h2 className="text-[32px] max-md:text-[24px] font-bold !text-center">
                    Turnover Calculator
                </h2>
                <p className="mb-6 text-[18px] max-md:text-[16px] text-center">
                    Use the fields below to calculate how much employee turnover is really
                    costing your organization.
                </p>

                <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>

                    <div className="text-[20px] max-md:text-[16px] font-bold mb-6">
                        Input the Following 2 Data Points
                    </div>
                    <InputGroup
                        label="Number of Employees Who Left the Company*"
                        id="employeesLeft"
                        name="employeesLeft"
                        type="text"
                        value={formValues.employeesLeft}
                        onChange={handleChange}
                        placeholder="Enter the number of employees who left the company"
                        labelClassname="calculator-input-label"
                        wrapperClassname="calculator-input-wrapper"
                        inputClassName="focus:!ring-0 focus:!border-[#3cce88]"
                    />

                    <InputGroup
                        label="Average Number of Employees During the Same Period*"
                        id="averageEmployees"
                        name="averageEmployees"
                        type="text"
                        value={formValues.averageEmployees}
                        onChange={handleChange}
                        placeholder="Enter the average number of employees"
                        labelClassname="calculator-input-label"
                        wrapperClassname="calculator-input-wrapper"
                        inputClassName="focus:!ring-0 focus:!border-[#3cce88]"/>
                </form>
                <div className="mt-10">
                    <div className="text-[20px] max-md:text-[16px] font-bold mb-6">
                        Base Data for Calculation
                    </div>

                    <div className="calculator-base-wrapper">
                        {[
                            {
                                label: "Recurring Hiring Costs per Turnover:",
                                value: formValues.hiringCosts,
                            },
                            {
                                label: "Revenue Opportunity Costs per Turnover:",
                                value: formValues.revenueCosts,
                            },
                            {
                                label: "Training Costs per Turnover:",
                                value: formValues.trainingCosts,
                            },
                            {
                                label: "Cost of Time to Full Productivity:",
                                value: formValues.unfilledTime,
                            },
                        ].map(({label, value}, index) => (
                            <div key={index} className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <div className="w-[6px] h-[6px] bg-black rounded-full mr-2"></div>
                                    <label>{label}</label>
                                </div>
                                <div className="font-medium  rounded text-left ml-2 w-[300px]  max-md:w-min ">
                                    {formatCurrency(value)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-10">
                    <div className="text-[20px] max-md:text-[16px] font-bold mb-6">
                        Output Results
                    </div>
                    <InputGroup
                        label="Total Cost of Turnover"
                        id="totalCost"
                        name="totalCost"
                        type="text"
                        value={formatCurrency(results.totalCost)}
                        labelClassname="calculator-input-label total text-[#3cce88] !font-[700]"
                        wrapperClassname="calculator-input-wrapper"
                        inputClassName="!ring-0 !border-[#3cce88] !font-[600]"
                        disabled
                    />

                    <InputGroup
                        label="Turnover Rate"
                        id="turnoverRate"
                        name="turnoverRate"
                        type="text"
                        value={`${results.turnoverRate.toLocaleString()}%`}
                        labelClassname="calculator-input-label total text-[#3cce88] !font-[700]"
                        wrapperClassname="calculator-input-wrapper "
                        inputClassName="!ring-0 !border-[#3cce88] !font-[600]"
                        disabled
                    />
                </div>
            </div>
        </div>
    );
};

export default Calculator;
