import React from "react";

const MilestoneBar = ({percentage, colorClass, label, index, staticValue}) => {

    console.log("staticValue", staticValue)

    return (
        <div
            className={`overflow-hidden relative flex flex-col justify-center items-center  max-w-full text-center min-h-[44px] text-white`}
            style={{width: `${100 - (12 - index) * 7}%`}}
        >

            <div className="text-white" style={{zIndex: 2, textShadow: '0px 1px 4px #00000052'}}>{label}{staticValue ? [`[${staticValue}]`] : ""}</div>
            <div className="absolute inset-0 w-full h-full flex justify-center items-center">
                <div
                    className={`w-full h-11 ${colorClass}`}
                    style={{
                        clipPath: window.innerWidth > 1200 ?
                            'polygon(0px 0px, 100% 0px, calc(100% - 20px) 100%, 20px 100%)'
                            : 'polygon(0px 0px, 100% 0px, calc(100% - 10px) 100%, 10px 100%)',
                    }}
                ></div>
            </div>

        </div>
    );
}

const MilestoneVisualization = ({data}) => {

    console.log("data", data)

    const milestones = [
        {stageKey: 'application', colorClass: 'bg-rose-800', index: 12},
        {stageKey: 'candidates', colorClass: 'bg-rose-600', index: 11},
        {stageKey: 'clarifications', colorClass: 'bg-rose-400', index: 10},
        {stageKey: '1st-interview', colorClass: 'bg-red-300', index: 9},
        {stageKey: 'post_interview_survey', colorClass: 'bg-orange-400', index: 8},
        {stageKey: '2nd-interview', colorClass: 'bg-orange-300', index: 7},
        {stageKey: 'document_review', colorClass: 'bg-amber-200', index: 6},
        {stageKey: 'rating_of_the_job', colorClass: 'bg-yellow-100', index: 5},
        {stageKey: 'ai_recomendation', colorClass: 'bg-lime-200', index: 4},
        {stageKey: 'final_hiring', colorClass: 'bg-lime-300', index: 3},
        {stageKey: 'offer_meeting', colorClass: 'bg-green-600', index: 2},
        {stageKey: 'training_milestones', colorClass: 'bg-emerald-800', index: 1},
    ];

    const getProc = (stageData) => {
        const percentage = stageData?.procentage ?? stageData?.proc;
        const formattedPercentage = percentage && Number.isInteger(percentage)
            ? `${percentage}%`
            : `${percentage?.toFixed(2)}%`;

        return `${stageData.count || 0} ${percentage ? `(${formattedPercentage})` : ''}`;
    };

    return (
        <section
            className="flex flex-col flex-auto justify-center max-w-[320px] xl:max-w-[546px] items-center text-lg font-bold text-white whitespace-nowrap">
            <h2 className="text-xl leading-8 text-zinc-800 mb-4 montserrat max-md:text-[18px]">Candidates per
                Milestone</h2>
            {milestones.map((milestone, idx) => {
                const stageData = data?.[milestone?.stageKey] || {count: 0, candidates: []};
                return (
                    <MilestoneBar
                        key={idx}
                        staticValue={(milestone?.stageKey === "offer_meeting" && !!getProc(stageData)) ? data?.offer : null}
                        index={milestone.index}
                        percentage={stageData.count || 0}
                        colorClass={milestone.colorClass}
                        label={getProc(stageData)}
                    />
                );
            })}
        </section>
    );
};

export default MilestoneVisualization;
