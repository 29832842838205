import {NavLink, useNavigate} from "react-router-dom";
import blocksImage from "../../../assets/img/home/blocks.png";
import blogImage from "../../../assets/img/home/blog.png";
import {Autoplay, Navigation} from "swiper/modules";
import React, {useEffect, useRef} from "react";
import {ReactComponent as ArrowIcon} from "../../../assets/img/home/arrowToFill.svg";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import '../../../assets/css/Swiper/styles.css';
import {getPublicSolutionPagesFetch} from "../../../redux/thunks/solutionThunks";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import getImageUrl from "../../../assets/helpers/getImageUrl";
import {format} from "date-fns";
import ReactPlayer from "react-player";
import {getDateEj} from "../../../pages/Public_New/Blogs";

export const BlogCard = ({date, title, description, image, id, slug}) => {
    const isImageUrl = (url) => {
        return /\.(jpeg|jpg|gif|png|webp|bmp|svg|ico)$/i.test(url);
    }
    const navigate = useNavigate()

    return (
        <div className="max-w-sm w-full overflow-hidden h-full flex flex-col">
            {isImageUrl(image) ?
                <img className="w-full h-[250px] rounded-[14px] object-cover cursor-pointer"
                     onClick={() => navigate(`/blogs/${slug}`)} src={image} alt="Article"/>
                :
                <video
                    className="w-full h-[250px] rounded-[14px] object-cover cursor-pointer"
                    autoPlay={false}
                    onClick={() => navigate(`/blogs/${slug}`)}
                    controls={false}
                >
                    <source src={image} type="video/mp4"/>
                </video>

            }
            <div className="mt-2 flex flex-col flex-auto">
                <span className="text-base font-medium">{date}</span>
                <h3 onClick={() => navigate(`/blogs/${slug}`)}
                    className="mt-2 text-2xl leading-7 font-semibold cursor-pointer">{title}</h3>
                <p className="mt-2 text-base font-medium flex-auto">{description}</p>
                <NavLink
                    to={`/blogs/${slug}`}
                    className="flex justify-center items-center font-semibold text-[18px] max-md:text-sm p-[8px] bg-[#37B34A] hover:bg-green-600 rounded-md text-white w-fit h-fit mt-3">
                    Read More
                </NavLink>
            </div>
        </div>
    );
};


export const BlogsSection = ({
                                 topSpacing = 'pt-[110px]',
                                 hasBgSection = true,
                                 showSubTitle = true,
                                 withButton = false,
                                 blocks = null,
                                 withBlock = true
                             }) => {
    const swiperRef = useRef(null);
    const dispatch = useDispatch()
    const {data, isLoading, categories} = useSelector(state => state.solution)

    useEffect(() => {
        dispatch(getPublicSolutionPagesFetch())
    }, [])
    if (isLoading) return null
    return (
        <section
            className={`w-full pb-[110px] ${topSpacing} flex flex-col gap-9 items-center relative font-['Raleway']`}>
            {hasBgSection && <div
                className="absolute top-0 left-0 w-full h-[140px] box-border bg-center"
                style={{backgroundImage: `url(${withBlock && blocksImage})`}}>
            </div>}

            <div className="container w-full">
                <div className="flex flex-col">
                    <div className="flex flex-col">
                        {showSubTitle && <span
                            className="text-lg font-semibold text-[#0056B3] mb-2">{blocks?.title || "Our Blogs"}</span>}
                        <div className="flex items-center justify-between max-xl:flex-col max-xl:items-start">
                            <h3 className="text-4xl sm:text-5xl md:text-6xl font-bold mt-2 max-w-[670px]">{blocks?.header || "Read more about us."}</h3>
                            <div className="flex gap-4 max-xl:mt-4">
                                <div
                                    className="bg-[#707072] p-3 rounded-full cursor-pointer w-[41px] h-[41px] hover:opacity-90 hover:scale-105 flex justify-center items-center"
                                    onClick={() => swiperRef.current?.slidePrev()}
                                >
                                    <ArrowIcon fill="#fff"/>
                                </div>
                                <div
                                    className="bg-[#707072]  rounded-full cursor-pointer w-[41px] h-[41px] hover:opacity-90 hover:scale-105 flex justify-center items-center p-3 rotate-180"
                                    onClick={() => swiperRef.current?.slideNext()}
                                >
                                    <ArrowIcon fill="#fff"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView="3"
                        autoplay={{delay: 3000, pauseOnMouseEnter: true}}
                        loop={true}
                        modules={[Navigation, Autoplay]}
                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                        className="swiper-with-buttons"
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {data.map((article, index) => (
                            <SwiperSlide key={index} className="!h-auto">
                                <BlogCard
                                    id={article.id}
                                    slug={article.slug}
                                    date={format(article.createdAt, "MM/dd/yyyy")}
                                    title={article.postTitle}
                                    description={article.name}
                                    image={getImageUrl(article.mainImage)}
                                    key={index}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                {blocks?.button &&
                    <NavLink
                        to="/request-demo"
                        className="flex justify-center items-center font-semibold text-lg sm:text-xl md:text-2xl bg-[#37B34A] hover:bg-green-600 rounded-md text-white h-[47px] w-[206px] mt-[40px] m-auto"
                    >
                        {blocks?.button}
                    </NavLink>}
            </div>
        </section>
    );
};
