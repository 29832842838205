import {useEffect, useState} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';
import {NextStepSectionSBM01} from '../base/NextStepSectionSBM01';
import api from "../../../api";
import {toast} from "react-toastify";
import PrimaryButton from "../../../components/base/PrimaryButton";


const categorizeQualifications = (aiConfig) => {
    return Object.keys(aiConfig).sort((a, b) => aiConfig?.[b]?.score - aiConfig?.[a]?.score).reduce(
        (acc, key) => {
            const value = aiConfig[key].score;
            if (
                ["ftm_pay_rate_level_7", "ftm_pay_rate_level_1", "_carOwner", "unemployment_rate"].includes(key) ||
                key.includes("snag_attitude") ||
                key.includes("snag_fit")
            ) {
                return acc
            }
            const score = parseFloat(value);
            if (isNaN(score)) return acc;
            const category = score > 70 ? "aboveAverage" :
                score >= 50 ? "average" :
                    "belowAverage";

            acc[category].push(`${key?.replaceAll("_", " ")?.replace(/\b\w/g, char => char?.toUpperCase())}`);
            return acc;
        },
        {aboveAverage: [], average: [], belowAverage: []}
    );
};

const QualificationSection = ({title, qualifications}) => (
    <div className="flex flex-col gap-4 list-disc">
        <h3 className="font-bold text-[22px]">{title}</h3>
        <ul className="list-disc ml-16 font-[600] text-[18px]">
            {qualifications.length > 0 ? (
                qualifications.map((qual, idx) => <li className="m-2" key={idx}>{qual}</li>)
            ) : (
                <li className="m-2 text-gray-500">No qualifications in this category.</li>
            )}
        </ul>
    </div>
);

const QACategories = ({aiConfig}) => {
    const {aboveAverage, average, belowAverage} = categorizeQualifications(aiConfig);

    return (
        <ul className="flex flex-col gap-6 list-disc ml-4">
            <li><QualificationSection title="Above Average Qualifications (From High to Low)" qualifications={aboveAverage}/>
            </li>
            <li><QualificationSection title="Average Qualifications (From High to Low)" qualifications={average}/></li>
            <li><QualificationSection title="Below Average Qualifications (From High to Low)" qualifications={belowAverage}/>
            </li>
        </ul>
    );
};


const Scores = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({})


    const [aiFields, setAiFields] = useState(null)

    const {job, application, jobId, applicationId, setActiveStep} = useOutletContext()
    useEffect(() => {
        setActiveStep(7)
        api.hiringAlgorithm.getFields(applicationId).then(response => {
            setAiFields(response.data)
        })
    }, [])

    const handlePrev = () => {
        navigate(`/SBM01_10_16/${jobId}/${applicationId}`)
    }
    const handleNext = () => {
        navigate(`/SBM01_10_5/${jobId}/${applicationId}`)
    }

    const aiConfig = aiFields && typeof aiFields === "object" && Object.keys(aiFields).reduce((config, key) => {
        config[key] = {
            key: key,
            name: key?.replaceAll("_", " ")?.replace(/\b\w/g, char => char?.toUpperCase()),
            score: aiFields[key],
        };
        return config;
    }, {});


    useEffect(() => {
        if (application?.assessments?.["qa-scores"]) {
            setFormData(application?.assessments?.["qa-scores"])
        }
    }, [application])


    const handleSubmit = async () => {
        setIsLoading(true)

        try {
            const resp = await api.jobApplications.updateApplication(jobId, applicationId, {
                assessments: {
                    ...application?.assessments || {},
                    ["qa-scores"]: formData
                }
            })
            if (resp.success) {
                toast.success("Saved")
            } else {
                toast.error(resp.message || "Something went wrong")
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        setIsLoading(false)
    }

    if (!aiConfig) {
        return null
    }


    const handleSaveFile = async () => {
        setIsLoading(true);

        try {
            const url = `${process.env.REACT_APP_API_URL}/${applicationId}`;
            const response = await fetch(url, {method: 'GET'});
            if (!response.ok) {
                throw new Error('Failed to fetch the file');
            }

            const blob = await response.blob();

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Hiring data.xlsx`;
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);

        } catch (err) {
            console.error(err);
            toast.error("Something went wrong while downloading the file");
        }

        setIsLoading(false);
    };


    return (
        <div className='flex-auto'>
            {/*<div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full  pb-20 lg:pt-14 lg:pb-24 mx-auto'>*/}
            {/*    <div className="border-b border-neutral-200 pb-[24px] md:pb-9 flex justify-between items-center w-full">*/}
            {/*        <span*/}
            {/*            className="text-[28px] md:text-3xl font-bold">Data for AI Recommendations*/}
            {/*        </span>*/}
            {/*        <PrimaryButton*/}
            {/*            isLoading={isLoading}*/}
            {/*            type='button'*/}
            {/*            onClick={handleSaveFile}*/}
            {/*            className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}*/}
            {/*        >*/}
            {/*            Download xlsx*/}
            {/*        </PrimaryButton>*/}
            {/*        <Fragment></Fragment>*/}
            {/*    </div>*/}
            {/*    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-x-[100px] gap-y-6 max-w-[900px]">*/}
            {/*        {!!Object.keys(aiConfig)?.length && Object.keys(aiConfig)?.filter((key) => !/^_/.test(key))?.map(key => {*/}
            {/*                return (<div className="flex w-full items-center justify-between">*/}
            {/*                    <div className="font-bold text-[18px]">*/}
            {/*                        {aiConfig[key].name}*/}
            {/*                    </div>*/}
            {/*                    <div*/}
            {/*                        className="flex gap-2 justify-center px-5 py-4 w-fit min-w-[96px] bg-white border border-solid border-zinc-300 max-md:px-5 min-h-[58px]">*/}
            {/*                        {aiConfig[key].score}*/}
            {/*                    </div>*/}
            {/*                </div>)*/}
            {/*            }*/}
            {/*        )*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full  pb-20 lg:pt-14 lg:pb-24 mx-auto'>
                <div className="border-b border-neutral-200 pb-[24px] md:pb-9"><span
                    className="text-[28px] md:text-3xl font-bold">EmployJoy.ai’s Algorithm Has Evaluated 60+ Critical Qualifications. Here is a Summary:</span>
                </div>
                <QACategories aiConfig={aiConfig}/>
            </div>
            <NextStepSectionSBM01 prev={handlePrev} next={handleNext}/>
        </div>
    )
}

export default Scores
